import { useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

export * from "./useDisclosure";
export * from "./useParams";

export function useNewRedirect() {
  const { pathname } = useLocation();
  const params = useMemo(() => new URLSearchParams(), []);
  params.set("r", pathname);
  return `?${params}`;
}

export function useRedirectPath(defaultPath: string) {
  const [params] = useSearchParams();
  return params.get("r") ?? defaultPath;
}
