import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

import { getMe, MeProps } from "../api/endpoints/me";
import { getUsers } from "../api/endpoints/user";

export interface User {
  firstName: string;
  lastName: string;
  permissions: string[];
}

interface UserContextValue {
  isPending: boolean;
  hasError: boolean;
  user: any[];
  userById: { [key: string]: any } | null;
  fetchUser: () => Promise<void>;
  meData: MeProps | undefined;
}

const UserContext = createContext<UserContextValue>({
  isPending: true,
  hasError: false,
  user: [],
  userById: null,
  fetchUser: async () => {},
  meData: undefined,
});

export function useUserContext() {
  return useContext(UserContext);
}

export function UserProvider({ children }: PropsWithChildren<{}>) {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(false);

  const [user, setUser] = useState<any[]>([]);
  const [userById, setUserById] = useState<any>({});
  const [me, setMe] = useState<MeProps | undefined>(undefined);
  const fetchUser = async () => {
    const userResponse = getUsers();
    const meResponse = getMe();
    const [response, me] = await Promise.all([userResponse, meResponse]);
    setMe(me.data);
    // TODO: I think below should be `response?.data`, but i don't use it so i won't touch it
    let data = response?.data;
    setUser(data);
    setUserById(
      data?.results?.reduce((acc: any, cur: any) => {
        acc[cur.id] = cur;
        return acc;
      }, {})
    );
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <UserContext.Provider
      value={{
        isPending,
        hasError: error,
        user,
        userById,
        fetchUser,
        meData: me,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
