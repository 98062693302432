import { MarkerType } from "@xyflow/react";

export const nodes = [
  // {
  //   id: 'annotation-1',
  //   type: 'annotation',
  //   draggable: false,
  //   selectable: false,
  //   data: {
  //     level: 1,
  //     label:
  //       'Built-in node and edge types. Draggable, deletable and connectable!',
  //     arrowStyle: {
  //       right: 0,
  //       bottom: 0,
  //       transform: 'translate(-30px,10px) rotate(-80deg)',
  //     },
  //   },
  //   position: { x: -80, y: -30 },
  // },
  {
    id: "cloud-services",
    type: "input",
    draggable: false,
    data: {
      label: "Cloud Services",
    },
    position: { x: 10, y: 0 },
  },
  {
    id: "internet-router",
    type: "default",
    draggable: false,
    data: {
      label: "Internet Router",
    },
    position: { x: 10, y: 100 },
  },
  // {
  //   id: 'annotation-2',
  //   type: 'annotation',
  //   draggable: false,
  //   selectable: false,
  //   data: {
  //     level: 2,
  //     label: 'Sub flows, toolbars and resizable nodes!',
  //     arrowStyle: {
  //       left: 0,
  //       bottom: 0,
  //       transform: 'translate(5px, 25px) scale(1, -1) rotate(100deg)',
  //     },
  //   },
  //   position: { x: 220, y: 200 },
  // },
  {
    id: "2-1",
    type: "group",
    draggable: false,
    data: {},
    position: {
      x: -10,
      y: 80,
    },
    style: {
      width: 580,
      height: 450,
      backgroundColor: "rgba(208, 192, 247, 0.2)",
    },
  },
  {
    id: "server-room",
    type: "group",
    data: {},
    draggable: false,
    position: {
      x: -0,
      y: 170,
    },
    style: {
      width: 180,
      height: 180,
      backgroundColor: "rgba(208, 192, 247, 0.2)",
    },
  },
  {
    id: "apps",
    type: "apps",
    draggable: false,
    data: {
      label: "Apps",
      items: [
        "AI Occupancy Detection",
        "Occupancy Detection",
        "Energy Optimization",
        "Alto Analytics Dashboard",
        "Alto Auto Gen PDF Report",
        "Smart Assign Interface for Energy Optimization",
        "Smart Assign Interface for Optimal Room Condition",
        "Guest Connex",
        "EcoMatcher Add-ons for Guest Connex",
        "Owner Connex",
        "Alto Report",
      ],
    },
    position: {
      x: 600,
      y: -10,
    },
    style: {
      width: 380,
      height: 350,
      backgroundColor: "rgba(208, 192, 247, 0.2)",
    },
  },
  {
    id: "guest-rooms",
    type: "guestRooms",
    draggable: false,
    data: {
      label: "Guest Rooms",
      items: [
        "Guest Room 1",
        "Guest Room 2",
        "Guest Room 3",
        "Guest Room 4",
        "Guest Room 5",
        "Guest Room 6",
        "Guest Room 7",
        "Guest Room 8",
        "Guest Room 9",
        "Guest Room 10",
      ],
    },
    position: {
      x: 200,
      y: 250,
    },
    style: {
      width: 300,
      height: 250,
      backgroundColor: "rgba(208, 192, 247, 0.2)",
    },
  },
  {
    id: "network-switch",
    type: "networkSwitch",
    draggable: false,
    data: {
      label: "Network Switch",
    },
    // type: 'tools',
    position: { x: 30, y: 200 },
    // style: {
    //   width: 80,
    //   height: 80,
    //   background: 'rgb(208, 192, 247)',
    // },
    // parentId: '2-1',
    // extent: 'parent',
  },
  {
    id: "iot-gateway",
    draggable: false,
    data: {
      label: "IoT Gateway",
    },
    style: {
      width: 80,
      height: 50,
      background: "rgb(208, 192, 247)",
      color: "white",
    },
    position: { x: 90, y: 290 },
  },
  {
    id: "pms",
    draggable: false,
    data: {
      label: "PMS",
    },
    style: {
      width: 40,
      height: 30,
      background: "rgb(208, 192, 247)",
      color: "white",
    },
    position: { x: 40, y: 290 },
  },
  {
    id: "2-3",
    type: "resizer",
    draggable: false,
    data: {
      label: "",
    },
    position: { x: 550, y: 140 },
    style: {
      width: 1,
      height: 1,
      background: "rgb(208, 192, 247)",
      color: "white",
    },
    parentId: "2-1",
    extent: "parent",
  },
  // {
  //   id: 'annotation-3',
  //   type: 'annotation',
  //   draggable: false,
  //   selectable: false,
  //   data: {
  //     level: 3,
  //     label: <>Nodes and edges can be anything and are fully customizable!</>,
  //     arrowStyle: {
  //       right: 0,
  //       bottom: 0,
  //       transform: 'translate(-35px, 20px) rotate(-80deg)',
  //     },
  //   },
  //   position: { x: -40, y: 570 },
  // },
  // {
  //   id: '3-2',
  //   type: 'textinput',
  //   position: { x: 150, y: 650 },
  //   data: {},
  // },
];

export const edges = [
  {
    id: "e1-2",
    source: "cloud-services",
    target: "internet-router",
    animated: true,
    type: "smoothstep",
    style: { stroke: "rgb(158, 118, 255)", strokeWidth: 2 },
  },
  {
    id: "e2-2",
    source: "internet-router",
    target: "network-switch",
    type: "smoothstep",
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  },
  {
    id: "e2-3",
    source: "network-switch",
    target: "2-3",
    type: "smoothstep",
    sourceHandle: "c",
    animated: true,

    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  },
  {
    id: "network-switch-to-guest-rooms",
    source: "network-switch",
    target: "guest-rooms",
    label: "Local Network",
    type: "smoothstep",
    sourceHandle: "c",
    animated: true,

    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  },
  {
    id: "network-switch-to-apps",
    source: "network-switch",
    target: "apps",
    type: "smoothstep",
    sourceHandle: "c",
    animated: true,

    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  },
  {
    id: "e2-4",
    source: "network-switch",
    target: "pms",
    label: "LAN",
    type: "smoothstep",
    sourceHandle: "a",
    animated: true,
    style: { stroke: "rgb(158, 118, 255)", strokeWidth: 2 },
  },
  {
    id: "network-switch-to-iot-gateway",
    source: "network-switch",
    target: "iot-gateway",
    label: "LAN",
    type: "smoothstep",
    sourceHandle: "b",
    animated: true,
    style: { stroke: "rgb(158, 118, 255)", strokeWidth: 2 },
  },
  // {
  //   id: 'e3-3',
  //   source: '2-3',
  //   sourceHandle: 'a',
  //   target: '3-2',
  //   type: 'button',
  //   animated: true,
  //   style: { stroke: 'rgb(158, 118, 255)', strokeWidth: 2 },
  // },
  // {
  //   id: 'e3-4',
  //   source: '2-3',
  //   sourceHandle: 'b',
  //   target: '3-1',
  //   type: 'button',
  //   style: { strokeWidth: 2 },
  // },
];
