import { P } from "../../../components/Common/Typo/typo";
import { COLOR } from "../../../styles/constants";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { RxCross2 } from "react-icons/rx";

const TermsConditionModal = ({ isOpen, setIsOpen, setIsAccept }: any) => {
  function closeModal() {
    setIsOpen(false);
  }

  const termContent = [
    {
      topic: "Eligibility",
      content:
        "You must have the legal capacity to enter into binding agreements.",
    },
    {
      topic: "Account",
      content: "Provide accurate information and keep your account secure.",
    },
    {
      topic: "Use",
      content: "Use the platform lawfully and responsibly.",
    },
    {
      topic: "Services",
      content:
        "We help with sustainability certification applications but cannot guarantee certification.",
    },
    {
      topic: "Fees",
      content:
        "Service fees will be communicated before any charges. You must pay all applicable fees.",
    },
    {
      topic: "Privacy",
      content:
        "Our Privacy Policy (https://www.altotech.ai/compliance/privacy-policy) describes how we handle your data.",
    },
    {
      topic: "Liability",
      content: `The platform is provided "as is" without warranties. We are not liable for indirect or consequential damages.`,
    },
    {
      topic: "Termination",
      content: "We can terminate or suspend your access at any time.",
    },
    {
      topic: "Free Trial",
      content:
        "New users are eligible for a free trial period of 1 month. During this period, you can access the platform without charge. After the trial period ends, users can contact us to purchase a subscription and continue using the service.",
    },
  ];

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="transform overflow-hidden rounded-[10px] bg-[#F9FAFF] border border-[#DBE4FF] w-[500px] p-4 text-left align-middle shadow-cardborder transition-all flex flex-col justify-between">
                  <div className="h-full overflow-y-scroll flex flex-col justify-between">
                    <div className="h-[80vh] overflow-y-scroll">
                      <div className="flex items-center justify-between">
                        <P
                          fontSize="18px"
                          fontWeight="600"
                          color={COLOR.primary.dark}
                        >
                          Terms & Conditions
                        </P>
                        <RxCross2
                          className="text-primary w-6 h-6 cursor-pointer"
                          onClick={() => setIsOpen(false)}
                        />
                      </div>
                      <div>
                        {termContent.map((term, index) => (
                          <div key={index} className="my-4 gap-[4px]">
                            <P fontSize="16px" fontWeight="600" color="#212529">
                              {index + 1}.{term.topic}
                            </P>
                            <P
                              fontSize="15px"
                              color="#212529"
                              className="mt-[12px]"
                            >
                              {term.content}
                            </P>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex items-center justify-end gap-[14px]">
                      <button className="px-[20px] h-[40px] rounded-lg border-2 border-primary">
                        <P
                          fontWeight="500"
                          color="#065BA9"
                          onClick={() => {
                            closeModal();
                            setIsAccept(false);
                          }}
                        >
                          Reject
                        </P>
                      </button>
                      <button
                        className="px-[20px] h-[40px] rounded-lg bg-primary"
                        onClick={() => {
                          closeModal();
                          setIsAccept(true);
                        }}
                      >
                        <P fontWeight="500" color="#FFF">
                          Accept
                        </P>
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default TermsConditionModal;
