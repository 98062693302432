import { P } from "../../../components/Common/Typo/typo";
import { FaCalendar } from "react-icons/fa6";
import { DataTableTimeComponent } from "./TableTime";

export const TimeDetails = ({
  dataTableTimeUseState,
  setDataTableTimeUseState,
}: any) => {
  return (
    <div className="mt-2 flex flex-col items-center justify-center gap-2 p-1 h-full">
      <div className="mt-2 flex items-stretch justify-center gap-2 p-1">
        {dataTableTimeUseState?.map((item: any) => (
          <div className="flex flex-col items-center gap-2 w-full shadow-lg h-full">
            <div className="flex items-center w-full gap-2 p-2">
              <div className="border p-2 rounded-full bg-primary-light">
                <FaCalendar />
              </div>
              <div className="border-2 w-full h-0"></div>
            </div>
            <div className="px-6 pb-3 flex flex-col gap-2">
              <P fontSize="12px" fontWeight="200">
                {item?.duration} {item?.unit_type}
                {item?.duration && item?.unit_type && item.duration > 1
                  ? "s"
                  : ""}
              </P>
              <P fontSize="16px" fontWeight="700">
                {item?.phase_name}
              </P>
              <P fontSize="12px" fontWeight="300">
                {item?.detail}
              </P>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full overflow-y-scroll">
        <DataTableTimeComponent
          data={dataTableTimeUseState}
          setData={setDataTableTimeUseState}
        />
      </div>
    </div>
  );
};
