import { AllPaths } from "../types";
import { tokenState } from "../../auth/tokenState";
import { AxiosInstance } from "axios";

export function interceptInsertAuthToken(client: AxiosInstance): number {
  return client.interceptors.request.use(async (config) => {
    const url = config.url as AllPaths;
    if (url !== "/v1/login/") {
      const accessToken = await tokenState.getAccessToken();
      if (accessToken !== null) {
        config.headers!.Authorization = `Bearer ${accessToken}`;
      } else {
        config.headers = {} as any;
      }
    }
    return config;
  });
}

export function interceptAutoRefreshAuthToken(client: AxiosInstance): number {
  return client.interceptors.response.use(
    (response) => response,
    async (error) => {
      const url = error.config.url as AllPaths;
      //@ts-ignore
      if (url !== "/v1/login/" && error.response.status === 401) {
        // try refreshing token
        tokenState.invalidateAccessToken();
        const accessToken = await tokenState.getAccessToken();
        if (accessToken !== null) {
          // got new access token, retry request
          return await client.request(error.config);
        }
      }
      throw error;
    }
  );
}
