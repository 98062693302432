import { useLoadingStatus } from "../components/GlobalLoadingIndicator";

export interface LoadingProps {
  loading?: boolean;
}

export function Loading({ loading = true }: LoadingProps) {
  useLoadingStatus(loading);
  return null;
}
