import { AppRoutes } from "./routes";
import { BrowserRouter } from "react-router-dom";
import { SWRConfig } from "swr";
import { swrFetcher } from "./api/http";
import { GlobalLoadingIndicator } from "./components/GlobalLoadingIndicator";
import { AuthProvider } from "./auth";
import { UserProvider } from "./auth/UserProvider";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <SWRConfig value={{ fetcher: swrFetcher }}>
      <BrowserRouter>
        <GlobalLoadingIndicator>
          <Toaster
            position="top-right"
            toastOptions={{
              style: {
                zIndex: 99999,
              },
            }}
          />
          <AuthProvider>
            <UserProvider>
              <AppRoutes />
            </UserProvider>
          </AuthProvider>
        </GlobalLoadingIndicator>
      </BrowserRouter>
    </SWRConfig>
  );
}

export default App;
