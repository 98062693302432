import { ReactNode } from "react";
// import { useNavigate } from "react-router-dom";
import { FaCaretDown } from "react-icons/fa";
import useSWR from "swr";
import { UserBadgeIcon } from "../../icons/UserBadgeIcon";
import { logout } from "../../auth";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

interface PageWrapperProps {
  children: ReactNode;
  title: string;
}

const getUsername = () => {
  const { data: me } = useSWR<any>([`/v1/me/`], {
    revalidateOnFocus: false,
  });
  const username = me?.first_name;
  if (username) return username.charAt(0).toUpperCase() + username.slice(1);
  return "AltoTech";
};

export function PageWrapper({ children, title }: PageWrapperProps) {
  return (
    <div className="h-screen flex flex-col bg-[#F9FAFF]">
      <div className="px-4 flex w-full py-3 items-center">
        <div className="flex-1">
          <h1 className="inline text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#0E7EE4] to-[#14B8B4]">
            {title}
          </h1>
        </div>
        <UserBadge />
      </div>
      <div
        className="relative px-4 pb-7 flex-1 flex flex-col bg-[#F9FAFF]"
        style={{ height: "calc(100% - 75px)" }}
      >
        {children}
      </div>
    </div>
  );
}

function UserBadge() {
  // const navigate = useNavigate();
  return (
    // <DropdownMenu>
    //   <DropdownMenuTrigger
    //     tw="
    //       flex items-center rounded-[20px] px-3 py-[10px] transition-colors
    //       hover:bg-primary-extraLight/30 active:bg-primary-extraLight/50 [&[aria-expanded=true]]:bg-primary-extraLight/50
    //     "
    //   >
    //     <UserBadgeIcon />
    //     <div tw="ml-3 flex flex-col items-start">
    //       <span tw="text-[14px] text-primary font-medium">{getUsername()}</span>
    //       <span tw="text-[10px] text-primary mt-[-3px]"></span>
    //     </div>
    //     <span tw="ml-6 text-primary">
    //       <FaCaretDown />
    //     </span>
    //   </DropdownMenuTrigger>
    //   <DropdownMenuContent>
    //     <DropdownMenuItem onClick={() => navigate("/change-password")}>
    //       Change password
    //     </DropdownMenuItem>
    //     <DropdownMenuItem onClick={logout}>Logout</DropdownMenuItem>
    //   </DropdownMenuContent>
    // </DropdownMenu>
    <Menu>
      <MenuButton className="flex items-center gap-2 rounded-[20px] px-3 py-[10px] transition-colors hover:bg-primary-extraLight/30 active:bg-primary-extraLight/50 [&[aria-expanded=true]]:bg-primary-extraLight/50">
        <UserBadgeIcon />
        <div className="ml-3 flex flex-col items-start">
          <span className="text-[14px] text-primary font-medium">
            {getUsername()}
          </span>
        </div>
        <span className="ml-6 text-primary">
          <FaCaretDown />
        </span>
      </MenuButton>
      <MenuItems
        transition
        anchor="bottom"
        className="w-[200px] mt-2 bg-white transition duration-100 ease-out shadow-badgelogout [--anchor-gap:var(--spacing-1)] data-[closed]:scale-95 data-[closed]:opacity-0 origin-top-right focus:outline-none
  p-0.5 rounded-[10px]"
      >
        {/* <MenuItem>
          <button className="w-full px-4 py-2 cursor-pointer rounded-lg text-sm flex items-center gap-3 hover:bg-primary/30 &[data-active=true]]:(outline-none hover:ring-1 hover:ring-primary)">
            Change password
          </button>
        </MenuItem> */}
        <MenuItem>
          <button
            className="w-full px-4 py-2 cursor-pointer rounded-lg text-sm flex items-center gap-3 hover:bg-primary/30 &[data-active=true]]:(outline-none hover:ring-1 hover:ring-primary)"
            onClick={logout}
          >
            Logout
          </button>
        </MenuItem>
      </MenuItems>
    </Menu>
  );
}
