import { useAuthContext } from "../../auth";
import { useUserContext } from "../../auth/UserProvider";
import { ErrorPage } from "../../components/ErrorPage";
import { Loading } from "../../components/Loading";
import { Fragment } from "react";
import { Link, Navigate, Outlet } from "react-router-dom";

export function AdminLayout() {
  const { isPending, hasError } = useUserContext();
  const { isLoggedIn } = useAuthContext();
  if (hasError) {
    return (
      <ErrorPage title="Session expired">
        <Link to="/logout">Logout</Link>
      </ErrorPage>
    );
  }

  if (isPending) {
    return <Loading />;
  }

  if (!isLoggedIn) {
    return <Navigate to={"/login"} replace />;
  }

  return (
    <Fragment>
      <div>
        <Outlet />
      </div>
    </Fragment>
  );
}
