import { Navigate, Route, Routes } from "react-router-dom";
import { Overview } from "../pages/overview";
import { Login } from "../pages/login";
import { AdminLayout } from "../layouts/admin";
import ChatInterface from "../pages/chatInterface";
import CartInterface from "../pages/cartInterface";
import Result from "../pages/result";
import { SignUp } from "../pages/sign-up";
import { ConfirmSignUp } from "../pages/confirm-sign-up";

export function AppRoutes() {
  return (
    <Routes>
      <Route index element={<Navigate to="admin/overview" replace />} />
      <Route path="/login" element={<Login />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/confirm-sign-up" element={<ConfirmSignUp />} />
      <Route path="/admin" element={<AdminLayout />}>
        <Route path="overview" element={<Overview />} />
        <Route path="chat" element={<ChatInterface />} />
        <Route path="cart" element={<CartInterface />} />
        <Route path="result" element={<Result />} />
      </Route>
    </Routes>
  );
}
