import { AiOutlineLoading } from "react-icons/ai";

import { COLOR } from "../styles/constants";

export function LoadingIndicator() {
  return (
    <div className="bg-black/20 fixed top-0 left-0 w-screen h-screen z-50 flex flex-col items-center justify-center animate-delayed-fade-in">
      <AiOutlineLoading className="text-[72px] text-primary animate-spin" />
    </div>
  );
}

export function LoadingIcon({ size = "40px", color = COLOR.primary.DEFAULT }) {
  return (
    <div tw="w-full h-full flex flex-col items-center justify-center animate-delayed-fade-in">
      <AiOutlineLoading
        tw="text-primary animate-spin"
        style={{ fontSize: size, color: color }}
      />
    </div>
  );
}
