import { useState, useCallback } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

export const DataTableTimeComponent = ({ data, setData }: any) => {
  const TableTimeDetailsStringCell = useCallback(
    ({ getValue, row, column, table }: any) => {
      const initialValue = getValue();
      const [value, setValue] = useState(initialValue);
      const onBlur = () => {
        table.options.meta?.updateData(row.index, column.id, value);
      };
      return (
        <input
          defaultValue={initialValue}
          onChange={(e) => {
            setData((prev: any) => {
              const newData = [...prev];
              newData[row.index] = {
                ...newData[row.index],
                [column.id]: e.target.value,
              };
              return newData;
            });
            setValue(e.target.value);
          }}
          onBlur={onBlur}
          className="w-full border border-gray-300 rounded px-2 py-1"
        />
      );
    },
    []
  );

  const TableTimeDetailsNumberCell = useCallback(
    ({ getValue, row, column, table }: any) => {
      const initialValue = getValue();
      const [value, setValue] = useState(initialValue);
      const onBlur = () => {
        table.options.meta?.updateData(row.index, column.id, value);
      };
      return (
        <input
          type="number"
          defaultValue={initialValue}
          onChange={(e) => {
            setData((prev: any) => {
              const newData = [...prev];
              newData[row.index] = {
                ...newData[row.index],
                [column.id]: e.target.value,
              };
              return newData;
            });
            setValue(e.target.value);
          }}
          onBlur={onBlur}
          className="w-full border border-gray-300 rounded px-2 py-1"
        />
      );
    },
    []
  );

  const TableTimeDetailsTextAreaCell = useCallback(
    ({ getValue, row, column, table }: any) => {
      const initialValue = getValue();
      const [value, setValue] = useState(initialValue);
      const onBlur = () => {
        table.options.meta?.updateData(row.index, column.id, value);
      };
      return (
        <textarea
          defaultValue={initialValue}
          onChange={(e) => {
            setData((prev: any) => {
              const newData = [...prev];
              newData[row.index] = {
                ...newData[row.index],
                [column.id]: e.target.value,
              };
              return newData;
            });
            setValue(e.target.value);
          }}
          onBlur={onBlur}
          className="w-full border border-gray-300 rounded px-2 py-1"
        />
      );
    },
    []
  );

  const TableTimeDetailsUnitCell = useCallback(
    ({ getValue, row, column, table }: any) => {
      const initialValue = getValue();
      const [value, setValue] = useState(initialValue);
      const onBlur = () => {
        table.options.meta?.updateData(row.index, column.id, value);
      };
      return (
        <select
          value={value}
          onChange={(e) => {
            setData((prev: any) => {
              const newData = [...prev];
              newData[row.index] = {
                ...newData[row.index],
                [column.id]: e.target.value,
              };
              return newData;
            });
            setValue(e.target.value);
          }}
          onBlur={onBlur}
          className="w-full border border-gray-300 rounded px-2 py-1"
        >
          <option value="day">Day</option>
          <option value="week">Week</option>
          <option value="month">Month</option>
          <option value="year">Year</option>
        </select>
      );
    },
    []
  );

  const columnHelperTableTime = createColumnHelper<any>();
  const columnsTableTime = [
    {
      ...columnHelperTableTime.accessor("phase_name", {
        header: "Phase Name",
        cell: TableTimeDetailsStringCell,
      }),
      width: "28%",
    },
    columnHelperTableTime.accessor("detail", {
      header: "Detail",
      cell: TableTimeDetailsTextAreaCell,
    }),
    {
      ...columnHelperTableTime.accessor("duration", {
        header: "Duration",
        cell: TableTimeDetailsNumberCell,
      }),
      width: "10%",
    },
    {
      ...columnHelperTableTime.accessor("unit_type", {
        header: "Duration Unit",
        cell: TableTimeDetailsUnitCell,
      }),
      width: "12%",
    },
  ];

  // const [timeDetailState, setTimeDetailState] = useState(data);
  const tableTableTime = useReactTable({
    data: data,
    columns: columnsTableTime,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table className="w-full border-2">
      <thead>
        {tableTableTime.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className="px-6 py-4 text-center text-[14px] font-medium text-gray-500 tracking-wider border-2"
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {tableTableTime.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                className="px-6 py-4 whitespace-nowrap text-[14px] text-center border-2"
                style={{
                  width: (cell.column.columnDef as { width?: string }).width,
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
