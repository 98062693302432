import { useState } from "react";
import { DataItem, Section } from "..";
import { P } from "../../../components/Common/Typo/typo";
import { COLOR } from "../../../styles/constants";
import TableBOQ from "./TableBOQ";

interface PriceDetailsProps {
  data?: DataItem[];
  setData: (data: DataItem[]) => void;
  discount?: number;
  setDiscount?: (discount?: number) => void;
}

const PriceDetails: React.FC<PriceDetailsProps> = ({
  data,
  setData,
  discount,
  setDiscount,
}) => {
  // Ensure data is an array before proceeding
  const capexData = Array.isArray(data)
    ? data.find((item) => item.type === "CAPEX")?.section || []
    : [];

  const opexData = Array.isArray(data)
    ? data.find((item) => item.type === "OPEX")?.section || []
    : [];

  const capexTotal = capexData.reduce((acc, section) => {
    return (
      acc +
      section.items.reduce((acc, item) => {
        return acc + item.quantity * item.unit_rate;
      }, 0)
    );
  }, 0);

  const opexTotal = opexData.reduce((acc, section) => {
    return (
      acc +
      section.items.reduce((acc, item) => {
        return acc + item.quantity * item.unit_rate;
      }, 0)
    );
  }, 0);

  return (
    <div className="h-full">
      <div
        className="relative p-4 grid grid-cols-2 gap-[8px]"
        style={{ height: "calc(100% - 80px)", maxHeight: "calc(100% - 80px)" }}
      >
        <div className="flex flex-col justify-between h-full overflow-hidden">
          <div className="flex flex-col h-full overflow-hidden mb-4">
            <h2 className="text-xl font-semibold mb-4">CAPEX</h2>
            <TableBOQ
              data={capexData}
              setData={(updatedCapex: Section[]) => {
                if (Array.isArray(data)) {
                  const updatedData = data.map((item) =>
                    item.type === "CAPEX"
                      ? { ...item, section: updatedCapex }
                      : item
                  );
                  setData(updatedData);
                }
              }}
            />
          </div>
          <P fontSize="16px" fontWeight="600" color={COLOR.black} align="right">
            Total {capexTotal?.toLocaleString()} THB
          </P>
        </div>
        <div className="flex flex-col justify-between h-full overflow-hidden">
          <div className="flex flex-col h-full overflow-hidden mb-4">
            <h2 className="text-xl font-semibold mb-4">OPEX</h2>
            <TableBOQ
              data={opexData}
              setData={(updatedOpex: Section[]) => {
                if (Array.isArray(data)) {
                  const updatedData = data.map((item) =>
                    item.type === "OPEX"
                      ? { ...item, section: updatedOpex }
                      : item
                  );
                  setData(updatedData);
                }
              }}
            />
          </div>
          <P fontSize="16px" fontWeight="600" color={COLOR.black} align="right">
            Total {opexTotal?.toLocaleString()} THB
          </P>
        </div>
      </div>
      <div className="flex justify-between items-center bg-white border border-white3 shadow-badgelogout rounded-[12px] h-[64px] py-[20px] px-[28px] gap-[20px]">
        <div className="flex gap-[20px] items-center">
          <P fontSize="15px" color={COLOR.black1}>
            CAPEX Discount
          </P>
          <input
            type="number"
            placeholder="0"
            value={discount}
            onChange={(e) =>
              setDiscount &&
              setDiscount(
                e.target.value === "" ? undefined : Number(e.target.value)
              )
            }
            className="w-[80px] h-[30px] border border-[#EDEFF9] rounded-[8px] px-[10px]"
            min="0"
            max="100"
          />
          <P fontSize="15px" color={COLOR.black1}>
            %
          </P>
        </div>
        <div className="flex gap-[20px] items-center">
          <P fontSize="15px" color={COLOR.black1}>
            Grand Total
          </P>
          {discount ? (
            <P fontSize="16px" color={COLOR.black1} className="line-through">
              {(capexTotal + opexTotal)?.toLocaleString()}
            </P>
          ) : null}
          <P fontSize="16px" fontWeight="600" color={COLOR.black1}>
            {(
              (capexTotal * (100 - (discount ?? 0))) / 100 +
              opexTotal
            )?.toLocaleString()}{" "}
            THB
          </P>
        </div>
      </div>
    </div>
  );
};

export default PriceDetails;
