import React, { useCallback, useEffect } from "react";
import {
  ReactFlow,
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
} from "@xyflow/react";

import {
  nodes as initialNodes,
  edges as initialEdges,
} from "./initial-elements.js";
import AnnotationNode from "./AnnotationNode.js";
import ToolbarNode from "./ToolbarNode.js";
import ResizerNode from "./ResizerNode.js";
import CircleNode from "./CircleNode.js";
import TextNode from "./TextNode.js";
import ButtonEdge from "./ButtonEdge.js";
import NetworkSwitchNode from "./NetworkSwitchNode.js";
import AppsNode from "./AppsNode.js";
import GuestRoomsNode from "./GuestRoomsNode.js";

import "@xyflow/react/dist/style.css";
import "./overview.css";

const nodeTypes = {
  annotation: AnnotationNode,
  tools: ToolbarNode,
  resizer: ResizerNode,
  circle: CircleNode,
  textinput: TextNode,
  networkSwitch: NetworkSwitchNode,
  apps: AppsNode,
  guestRooms: GuestRoomsNode,
};

const edgeTypes = {
  button: ButtonEdge,
};

const nodeClassName = (node: any) => node.type;

const OverviewFlow = ({ nodesData }: any) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(nodesData);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const onConnect = useCallback(
    (params: any) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  useEffect(() => {
    setNodes(nodesData);
  }, [nodesData]);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      fitView
      attributionPosition="top-right"
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      className="overview"
    >
      <MiniMap zoomable pannable nodeClassName={nodeClassName} />
      <Controls />
      <Background />
    </ReactFlow>
  );
};

export default OverviewFlow;
