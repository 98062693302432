import { memo } from "react";
import { Handle, Position, NodeResizer } from "@xyflow/react";

function GuestRoomsNode({ data }: any) {
  return (
    <div>
      <NodeResizer minWidth={50} minHeight={50} />
      <Handle type="target" position={Position.Top} />
      <div className="p-2 text-left text-sm">{data.label}</div>
      <div className="flex flex-wrap space-x-6 p-2 ">
        {data.items ? (
          <div className="flex flex-wrap gap-[12px]">
            <div className="flex-1 w-1/2">
              {data.items.slice(0, 5).map((item: any, index: number) => (
                <div key={index} className="text-xxs">
                  • {item.name} x {item.quantity}
                </div>
              ))}
            </div>
            {data.items.length > 5 && (
              <div className="flex-1 w-1/2">
                {data.items.slice(5).map((item: any, index: number) => (
                  <div key={index} className="text-xxs">
                    • {item.name} x {item.quantity}
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          "no items available"
        )}
      </div>
      <div
        style={{
          display: "flex",
          position: "absolute",
          bottom: 0,
          width: "100%",
          justifyContent: "space-evenly",
          left: 0,
        }}
      ></div>
    </div>
  );
}

export default memo(GuestRoomsNode);
