import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PageWrapper } from "../../layouts/admin/PageWrapper";
import { P } from "../../components/Common/Typo/typo";
import HeaderTab from "./components/HeaderTab";
import useSWR from "swr";
import { TimeDetails } from "./components/TimeDetails";
import PriceDetails from "./components/PriceDetails";
import { httpClient } from "../../api/http";
import SavingDetails from "./components/SavingDetails";
import toast from "react-hot-toast";

export interface Item {
  item_name: string;
  tag?: string[];
  description: string;
  quantity: number;
  unit: string;
  unit_rate: number;
  location?: string;
}

export interface Section {
  section_name: string;
  items: Item[];
}

export interface DataItem {
  _id: string;
  project: string;
  type: string;
  section: Section[];
  location?: string;
}

interface TimeDetailType {
  order?: number;
  phase_name: string;
  detail?: string;
  duration?: number;
  unit_type?: string;
}

const CartInterface = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get("project_id");
  const [data, setData] = useState<DataItem[]>([]);
  const [selectedTab, setSelectedTab] = useState("price");
  const [discount, setDiscount] = useState<number | undefined>();

  const { data: project, mutate: project_mutate } = useSWR([
    `/v1/projects/${projectId}/`,
  ]);

  const { data: project_boq, mutate: boq_mutate } = useSWR([
    "/v1/project_boq/",
    { project: projectId },
  ]);

  useEffect(() => {
    if (project) {
      setDiscount(project?.data?.discount);
    }
  }, [project]);

  useEffect(() => {
    if (project_boq?.data) {
      setData(project_boq.data);
    }
  }, [project_boq]);

  const handleOnSave = async () => {
    const transformedData = [...data];

    try {
      toast.loading("Saving data..."); // Show loading toast
      for (let i = 0; i < transformedData.length; i++) {
        await httpClient.patch(`/v1/project_boq/${data[i]?._id}/`, {
          section: transformedData[i].section,
        });
      }
      await httpClient.patch(`/v1/projects/${projectId}/`, {
        timeline: dataTableTimeUseState,
        data: savingFormData,
        discount: discount,
      });
      await boq_mutate();
      await project_mutate();
      // Handle success response
      toast.dismiss(); // Dismiss the loading toast
      toast.success("Data saved successfully"); // Show success toast
    } catch (error) {
      // Handle network errors
      console.error("An error occurred while saving data:", error);
      toast.dismiss(); // Dismiss the loading toast
      toast.error("Failed to save data"); // Show error toast
    }
  };

  const [dataTableTimeUseState, setDataTableTimeUseState] = useState([]);

  useEffect(() => {
    if (project) {
      const data = project?.data?.timeline ?? [];
      const sortedData = data.sort((a: TimeDetailType, b: TimeDetailType) =>
        !b?.order ? -1 : !a?.order ? 1 : a.order - b.order
      );
      setDataTableTimeUseState(sortedData);
    }
  }, [project]);

  const [savingFormData, setSavingFormData] = useState<any>({});

  useEffect(() => {
    setSavingFormData({
      yearly_electricity_bill: project?.data?.data?.yearly_electricity_bill,
      estimated_yearly_savings_percentage:
        project?.data?.data?.estimated_yearly_savings_percentage,
      total_rooms: project?.data?.data?.total_rooms,
      price_per_night: project?.data?.data?.price_per_night,
      occupancy_rate: project?.data?.data?.occupancy_rate,
      upside_percentage: project?.data?.data?.upside_percentage,
      man_hour_saving: project?.data?.data?.man_hour_saving,
      cost_per_hour: project?.data?.data?.cost_per_hour,
    });
  }, [project]);

  return (
    <PageWrapper title="BOQ Review">
      <HeaderTab selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <div className="h-[90%] overflow-y-scroll">
        {selectedTab === "price" ? (
          <PriceDetails
            data={data}
            setData={setData}
            discount={discount}
            setDiscount={setDiscount}
          />
        ) : selectedTab === "time" ? (
          <TimeDetails
            dataTableTimeUseState={dataTableTimeUseState}
            setDataTableTimeUseState={setDataTableTimeUseState}
          />
        ) : (
          <SavingDetails
            data={savingFormData}
            setData={setSavingFormData}
            project={project?.data}
            projectBOQ={data}
            discount={discount}
          />
        )}
      </div>
      <div className="mt-2 flex items-center justify-center gap-2">
        <button
          className="bg-primary px-[20px] py-[6px] rounded-lg"
          onClick={() => navigate(`/admin/chat?project_id=${projectId}`)}
        >
          <P fontSize="14px" fontWeight="600" color="#FFF">
            Prev
          </P>
        </button>
        <button
          className="bg-primary px-[20px] py-[6px] rounded-lg"
          onClick={handleOnSave}
        >
          <P fontSize="14px" fontWeight="600" color="#FFF">
            Save
          </P>
        </button>
        <button
          className="bg-primary px-[20px] py-[6px] rounded-lg"
          onClick={async () => {
            try {
              toast.loading("Saving data..."); // Show loading toast
              for (let i = 0; i < data.length; i++) {
                await httpClient.patch(`/v1/project_boq/${data[i]?._id}/`, {
                  section: data[i].section,
                });
              }
              await httpClient.patch(`/v1/projects/${projectId}/`, {
                timeline: dataTableTimeUseState,
                data: savingFormData,
                discount: discount,
                status: "result",
              });
              toast.dismiss(); // Dismiss the loading toast
              toast.success("Data saved successfully"); // Show success toast
              navigate(`/admin/result?project_id=${projectId}`);
            } catch (error) {
              // Handle network errors
              console.error("An error occurred while saving data:", error);
              toast.dismiss(); // Dismiss the loading toast
              toast.error("Failed to save data"); // Show error toast
            }
          }}
        >
          <P fontSize="14px" fontWeight="600" color="#FFF">
            Next
          </P>
        </button>
      </div>
    </PageWrapper>
  );
};

export default CartInterface;
