import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { P } from "../../../components/Common/Typo/typo";
import { httpClient } from "../../../api/http";
import toast from "react-hot-toast";

type ProjectData = {
  _id: string;
  name: string;
  product_type: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  note: string;
  status: string;
};

const columnHelper = createColumnHelper<ProjectData>();

const OverviewTable = ({
  data: realdata,
  isLoading,
  mutate,
  setIsNoteOpen,
  setNoteId,
}: any) => {
  const navigate = useNavigate();

  const handleView = (row: ProjectData) => {
    // Implement view logic
    navigate(`/admin/result/?project_id=${row._id}`);
  };

  const handleEdit = (row: ProjectData) => {
    // Implement edit logic
    if (row.status === "result") {
      navigate(`/admin/cart/?project_id=${row._id}`);
    } else {
      navigate(`/admin/${row.status}/?project_id=${row._id}`);
    }
  };

  const handleDelete = async (row: ProjectData) => {
    // Implement delete logic
    try {
      await httpClient.delete("/v1/projects/" + row._id);
      mutate();
      toast.success("Success delete");
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    columnHelper.accessor("_id", {
      cell: (info) => (
        <p className="text-[#0E7EE4] font-semibold">{info.getValue()}</p>
      ),
      header: () => <p className="text-[#0E7EE4] font-semibold">Id</p>,
    }),
    columnHelper.accessor("name", {
      cell: (info) => <p>{info.getValue() || "-"}</p>,
      header: () => <p className="text-[#0E7EE4] font-semibold">Name</p>,
    }),
    columnHelper.accessor("product_type", {
      cell: (info) => (
        <p className="capitalize">
          {info.getValue() ? info.getValue().toLocaleString() : "-"}
        </p>
      ),
      header: () => <p className="text-[#0E7EE4] font-semibold">Type</p>,
    }),
    columnHelper.accessor("createdAt", {
      cell: (info) => {
        const date = info.getValue();
        const formattedDate = date ? dayjs(date).format("DD-MM-YYYY") : "-";
        return <p>{formattedDate}</p>;
      },
      header: () => (
        <p className="text-[#0E7EE4] font-semibold">Created Date</p>
      ),
    }),
    columnHelper.accessor("updatedAt", {
      cell: (info) => {
        const date = info.getValue();
        const formattedDate = date ? dayjs(date).format("DD-MM-YYYY") : "-";
        return <p>{formattedDate}</p>;
      },
      header: () => <p className="text-[#0E7EE4] font-semibold">Last Update</p>,
    }),
    {
      id: "action", // Change the id to "action"
      cell: (info: CellContext<ProjectData, string>) => (
        <div className="flex items-center justify-center space-x-2">
          {info.row.original.status === "result" && (
            <button
              onClick={() => handleView(info.row.original)}
              className="px-2 py-1 bg-primary text-white rounded hover:bg-primary-dark"
            >
              View
            </button>
          )}
          <button
            onClick={() => handleEdit(info.row.original)}
            className="px-2 py-1 bg-green-500 text-white rounded hover:bg-green-600"
          >
            Edit
          </button>
          <button
            onClick={() => handleDelete(info.row.original)}
            className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
          >
            Delete
          </button>
        </div>
      ),
      header: () => <p className="text-[#0E7EE4] font-semibold">Action</p>,
    },
    columnHelper.accessor("note", {
      cell: (info) => (
        <div
          onClick={() => {
            setNoteId(info.row.original._id);
            setIsNoteOpen(true);
          }}
        >
          <P fontSize="14px">{info.getValue() || "-"}</P>
        </div>
      ),
      header: () => <p className="text-[#0E7EE4] font-semibold">Note</p>,
    }),
  ];
  const table = useReactTable({
    data: realdata?.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="overflow-x-auto border border-gray-200">
      <table className="min-w-full divide-y divide-gray-200 border-collapse">
        <thead className="bg-gray-50">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => (
                <th
                  key={header.id}
                  className={`px-6 py-4 text-center text-[14px] font-medium text-gray-500 tracking-wider
                                ${
                                  index !== 0 ? "border-l border-gray-200" : ""
                                }`}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell, index) => (
                <td
                  key={cell.id}
                  className={`px-6 py-4 whitespace-nowrap text-[14px] text-center
                                ${
                                  index !== 0 ? "border-l border-gray-200" : ""
                                }`}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OverviewTable;
