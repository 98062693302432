import { tokenState } from "./tokenState";
import { httpClient, post } from "../api/http";

export async function login(email: string, password: string) {
  const response = await post(
    "/v1/login/",
    {
      email,
      password,
    },
    true
  );
  tokenState.setTokens(response);
  tokenState.setUsername(email);
}

export async function signUp(
  username: string,
  password: string,
  confirm_password: string,
  email: string,
  first_name: string,
  last_name: string,
  organization_name: string
) {
  const response = await post(
    "/v1/register/",
    {
      username,
      password,
      confirm_password,
      email,
      first_name,
      last_name,
      organization_name,
    },
    true
  );
}

export const changePassword = async (
  oldPassword: string,
  newPassword: string
) => {
  return httpClient.patch(`/v1/change_password/`, {
    old_password: oldPassword,
    new_password: newPassword,
  });
};

export const setNewForgotPassword = async (token: string, Password: string) => {
  return httpClient.patch(`v1/reset_password/`, {
    token: token,
    password: Password,
  });
};

export async function logout() {
  localStorage.removeItem("isRefreshed");
  localStorage.removeItem("access");
  tokenState.clearTokens();
  window.location.href = "/";
}

export const forgetPassword = async (email: string) => {
  const response = await post("/v1/forgot_password/", { email }, true);
};
