import { memo } from 'react';
import { Handle, Position, NodeResizer } from '@xyflow/react';

function AppsNode({ data }) {
  return (
    <div className="bg-gradient-to-r from-pink-500 to-blue-500 h-full opacity-70">
      <NodeResizer minWidth={50} minHeight={50} />
      <Handle type="target" position={Position.Left} />
      <div className="p-2 text-left text-sm">{data.label}</div>
      <div className="flex flex-wrap gap-2 p-2">
        {data.items ? (
          data.items.map((item, index) => (
            <div key={index} className="btn bg-white rounded-lg p-2 text-xxs">
              {item}
            </div>
          ))
        ) : (
          'no items available'
        )}
      </div>
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          bottom: 0,
          width: '100%',
          justifyContent: 'space-evenly',
          left: 0,
        }}
      >
      </div>
    </div>
  );
}

export default memo(AppsNode);
