import { TiPlus } from "react-icons/ti";
import { PageWrapper } from "../../layouts/admin/PageWrapper";
import OverviewTable from "./components/OverviewTable";
import NewProjectModal from "./components/NewProjectModal";
import { useEffect, useState } from "react";
import useSWR from "swr";
import NoteModal from "./components/NoteModal";

export function Overview() {
  const [isOpen, setIsOpen] = useState(false);
  const [isNoteOpen, setIsNoteOpen] = useState(false);
  const [noteId, setNoteId] = useState("");
  const { data: realdata, isLoading, mutate } = useSWR([`/v1/projects/`]);
  const [noteValue, setNoteValue] = useState<any>();
  useEffect(() => {
    const note = realdata?.data?.find((item: any) => item._id === noteId);
    if (note?.note) {
      setNoteValue(note?.note);
    } else {
      setNoteValue(undefined);
    }
  }, [noteId]);
  return (
    <PageWrapper title="Overview">
      <div className="flex items-end justify-end">
        <button
          className="bg-primary hover:bg-primary-dark text-white font-medium py-[6px] px-3 rounded-md flex items-center gap-2 shadow-buttoncard"
          onClick={() => setIsOpen(true)}
        >
          <TiPlus className="inline-block" />
          New
        </button>
      </div>
      <div className="mt-2">
        <OverviewTable
          data={realdata}
          isLoading={isLoading}
          mutate={mutate}
          setIsNoteOpen={setIsNoteOpen}
          setNoteId={setNoteId}
          setNoteValue={setNoteValue}
        />
      </div>
      <NewProjectModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <NoteModal
        isOpen={isNoteOpen}
        setIsOpen={setIsNoteOpen}
        noteId={noteId}
        noteValue={noteValue}
        mutate={mutate}
      />
    </PageWrapper>
  );
}
