import { apiUrl } from "../api/http";
import { JwtTokenResponse } from "../api/endpoints/auth";
import { LocalStoragePersister, Persister } from "../utils/persister";
import { sharedPromise } from "../utils/sharedPromise";
import axios from "axios";

// const clientId = import.meta.env.VITE_CLIENT_ID

class TokenState {
  refreshToken: Persister<string> = new LocalStoragePersister("refreshToken");
  accessToken: Persister<string> = new LocalStoragePersister("accessToken");
  hotel_code: Persister<string> = new LocalStoragePersister("hotel_code");
  username: Persister<string> = new LocalStoragePersister("username");

  invalidateAccessToken = () => {
    this.accessToken.set(null);
  };

  getAccessToken = async (): Promise<string | null> => {
    const accessToken = this.accessToken.get();
    if (accessToken !== null) {
      return accessToken;
    }
    await this.refresh();
    return this.accessToken.get();
  };

  setTokens = (jwtResponse: JwtTokenResponse) => {
    const { access, refresh } = jwtResponse;
    this.accessToken.set(access);
    this.refreshToken.set(refresh);
  };

  // setHotelCode = (response: any) => {
  //   const { hotel } = response;
  //   this.hotel_code.set(hotel.hotel_code);
  // };

  setUsername = (username: any) => {
    this.username.set(username);
  };

  // getHotelCode = async (): Promise<string | null | undefined> => {
  //   const hotel_code = this.hotel_code.get();
  //   if (hotel_code !== null) {
  //     return hotel_code;
  //   }
  // };

  getUsername = (): string | null | undefined => {
    const username = this.username.get();
    if (username !== null) {
      return username;
    }
    return undefined;
  };

  clearTokens = () => {
    this.refreshToken.set(null);
    this.accessToken.set(null);
  };

  private refresh = sharedPromise(async () => {
    const refreshToken = this.refreshToken.get();
    if (refreshToken === null) {
      return;
    }
    try {
      const response = await axios.post(`${apiUrl}/v1/refresh/`, {
        refresh: refreshToken,
      });
      this.setTokens({ access: response.data.access, refresh: refreshToken });
    } catch (e: unknown) {
      this.clearTokens();
    }
  });
}

export const tokenState = new TokenState();
