import { httpClient } from "../../api/http";
import BgImage from "../../assets/login/NewBackground.jpg";
import { signUp } from "../../auth";
import { P } from "../../components/Common/Typo/typo";
import { COLOR } from "../../styles/constants";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

type FormData = {
  username: string;
  password: string;
  confirm_password: string;
  email: string;
  first_name: string;
  last_name: string;
  organization_name: string;
};

export function ConfirmSignUp() {
  const { handleSubmit } = useForm<FormData>();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    try {
      const {
        username,
        password,
        confirm_password,
        email,
        first_name,
        last_name,
        organization_name,
      } = data;
      await toast.promise(
        signUp(
          username,
          password,
          confirm_password,
          email,
          first_name,
          last_name,
          organization_name
        ),
        {
          loading: "Sign Up...",
          success: <b>Sign Up successful!</b>,
          error: (error) => {
            if (axios.isAxiosError(error) && error.response?.status === 400) {
              return <b>Incorrect username or password</b>;
            }
            return <b>Incorrect username or password</b>;
          },
        }
      );
    } catch (error: unknown) {
    } finally {
      setIsLoading(false);
    }
  });
  const navigate = useNavigate();

  const handleOnClick = async () => {
    const promise = httpClient.get(`/v1/resend_verification_email/`);

    toast.promise(promise, {
      loading: "Sending verification email...",
      success: "Verification email sent successfully!",
      error: "Please waiting for 2 minutes before sending another email.",
    });

    try {
      await promise;
      navigate("/login");
    } catch (err: any) {
      // const errorMessage =
      //   err?.response?.data?.message || "An unexpected error occurred";
      // toast.error(errorMessage);
    }
  };

  return (
    <div className="bg-[#F9FAFF] grid grid-cols-12 min-h-screen gap-x-0">
      <div className="hidden md:block col-span-5">
        <div
          className="h-full flex justify-center items-end overflow-hidden min-h-screen w-full relative bg-no-repeat bg-cover bg-center"
          style={{
            backgroundImage: `url(${BgImage})`,
            backgroundBlendMode: "overlay",
          }}
        />
      </div>
      <div className="col-span-12 md:col-span-7 flex justify-center pt-20">
        <form
          className="flex flex-col items-center gap-4 min-w-[340px]"
          onSubmit={onSubmit}
        >
          <div className="border border-[#EDEFF9] rounded-[12px] w-fit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
            >
              <path
                d="M49.9503 45.8831L37.0044 15.4464C36.8289 15.0335 36.4943 14.707 36.0814 14.5127C35.8142 14.3885 35.5119 14.3184 35.1989 14.3184H28.9162C28.6031 14.3184 28.3035 14.3885 28.0391 14.5127C28.0418 14.5235 28.0445 14.537 28.0499 14.5478L32.0602 23.9772L40.7745 44.469C40.9715 44.9332 41.4007 45.2543 41.9026 45.3191C44.5339 45.6564 46.9952 46.126 49.2325 46.7062C49.7236 46.8331 50.1474 46.3473 49.9503 45.8831Z"
                fill="url(#paint0_linear_152_3688)"
              />
              <path
                d="M35.2016 14.3184H28.9162C28.6031 14.3184 28.3035 14.3885 28.0391 14.51C28.0418 14.5208 28.0445 14.5343 28.0499 14.5451L32.0602 23.9476L36.0841 14.5127C35.8142 14.3885 35.5146 14.3184 35.2016 14.3184Z"
                fill="#212529"
              />
              <path
                d="M35.2021 14.3184H28.9166C28.6036 14.3184 28.304 14.3885 28.0395 14.51C27.6239 14.7016 27.2893 15.0281 27.1112 15.441L14.0356 46.0936C13.8926 46.431 14.203 46.7845 14.5592 46.69C16.9098 46.0639 19.5168 45.562 22.3101 45.2111C22.7527 45.1545 23.1332 44.8684 23.3086 44.4609L32.0607 23.9449L36.0846 14.51C35.8147 14.3885 35.5151 14.3184 35.2021 14.3184Z"
                fill="url(#paint1_radial_152_3688)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_152_3688"
                  x1="30.8281"
                  y1="18.5102"
                  x2="48.8557"
                  y2="44.0977"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FBED22" />
                  <stop offset="1" stop-color="#37B44A" />
                </linearGradient>
                <radialGradient
                  id="paint1_radial_152_3688"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(32.4997 15.9311) scale(38.0123 37.6136)"
                >
                  <stop stop-color="#28AAE1" />
                  <stop offset="0.2881" stop-color="#209ED9" />
                  <stop offset="0.8064" stop-color="#097FC4" />
                  <stop offset="1" stop-color="#0071BB" />
                </radialGradient>
              </defs>
            </svg>
          </div>

          <div className="text-center" style={{ margin: "1em 0" }}>
            <P fontSize="32px" fontWeight="600" color={COLOR.primary.dark}>
              Welcome to Proposal Builder App
            </P>
            <div className="mt-[12px]">
              <P fontSize="18px" fontWeight="500">
                Create and manage professional proposals quickly and efficiently
              </P>
            </div>
          </div>
          <div className="mt-[49px] mb-[24px] text-center">
            <P fontSize="24px" fontWeight="600" color={COLOR.primary.dark}>
              Thank you for registration!
            </P>
          </div>
          <P fontSize="18px" fontWeight="500" className="w-[350px] text-center">
            Please check your email.
          </P>
          <P
            fontSize="18px"
            fontWeight="500"
            className="w-[350px] text-center mt-[-16px] mb-[40px]"
          >
            We have already sent you verification.
          </P>
          <div className="w-full flex flex-col items-center justify-center">
            <button
              onClick={() => navigate("/login")}
              disabled={isLoading}
              className="w-[350px] border px-[20px] py-[6px] rounded-lg bg-primary h-[40px]"
            >
              <P fontSize="14px" fontWeight="500" color="#FFF">
                Sign In
              </P>
            </button>
            <div className="mt-[8px]">
              <div className="flex gap-[8px]">
                <P color={COLOR.black2}>Didn’t receive email?</P>
                <P
                  fontWeight="500"
                  color={COLOR.primary.dark}
                  onClick={() => handleOnClick()}
                  className="cursor-pointer"
                >
                  Resend
                </P>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
