import styled from "@emotion/styled";
import React from "react";

export interface TypoProps {
  children?: React.ReactNode;
  font?: string;
  fontSize?: string | number;
  lineHeight?: string | number;
  fontWeight?: string;
  color?: string;
  align?: string;
  span?: boolean;
}

export const H2 = styled.h2<TypoProps>`
  font-family: ${({ font }) => font || "Poppins"};
  font-size: ${({ fontSize }) => fontSize || "18px"};
  line-height: ${({ lineHeight }) => lineHeight || "26px"};
  text-align: ${({ align }) => (align ? align : "left")};
  color: ${({ color }) => color || "black"};
  font-weight: ${({ fontWeight }) => fontWeight || "600"};
  ${({ span }) => span && "display: inline-block;"}
`;

export const H3 = styled.h3<TypoProps>`
  font-family: ${({ font }) => font || "Poppins"};
  font-size: ${({ fontSize }) => fontSize || "16px"};
  line-height: ${({ lineHeight }) => lineHeight || "23px"};
  text-align: ${({ align }) => (align ? align : "left")};
  color: ${({ color }) => color || "black"};
  font-weight: ${({ fontWeight }) => fontWeight || "600"};
  ${({ span }) => span && "display: inline-block;"}
`;

export const P = styled.p<TypoProps>`
  font-family: ${({ font }) => font || "Poppins"};
  font-size: ${({ fontSize }) => fontSize || "12px"};
  line-height: ${({ lineHeight }) => lineHeight};
  text-align: ${({ align }) => (align ? align : "")};
  color: ${({ color }) => color || "black"};
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  ${({ span }) => span && "display: inline-block;"}
`;
