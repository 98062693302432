import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import { LoadingIndicator } from "../components/LoadingIndicator";

interface LoadingContextValue {
  counter: number;
  setCounter: Dispatch<SetStateAction<number>>;
}

const LoadingContext = createContext<LoadingContextValue>(
  null as unknown as LoadingContextValue
);

export function GlobalLoadingIndicator({ children }: PropsWithChildren<{}>) {
  const [counter, setCounter] = useState(0);
  const loading = counter > 0;
  return (
    <LoadingContext.Provider value={{ counter, setCounter }}>
      {loading && <LoadingIndicator />}
      {children}
    </LoadingContext.Provider>
  );
}

export function useLoadingStatus(loading: boolean = true) {
  const { setCounter } = useContext(LoadingContext);
  useEffect(() => {
    if (!loading) return;
    setCounter((counter) => counter + 1);
    return () => setCounter((counter) => counter - 1);
  }, [loading]);
}
