export const COLOR = {
  cupink: {
    verylight: "#F4E2E8",
    light: "#FFE2EB",
    main: "#FE6F9A",
    dark: "#E14960",
  },
  primary: {
    DEFAULT: "#0E7EE4",
    dark: "#065BA9",
    medium: "#55A6F2",
    light: "#8BC6FF",
    extraLight: "#C0E8FF",
  },
  secondary: {
    DEFAULT: "#14B8B4",
    dark: "#2E8285",
    medium: "#14B0BC",
    light: "#59D1CE",
    light2: "#50F2EE",
    extraLight: "#CBF0EF",
  },
  tertiary: {
    DEFAULT: "#FF7A00",
    dark: "#EA663F",
    medium: "#F5A623",
    light: "#F9C36A",
    extraLight: "#FAD291",
  },
  altoDark: {
    DEFAULT: "#212529",
    medium: "#788796",
    light: "#BDBEC8",
  },
  orange: "#FDA794",
  orange1: "#FFD7B2",
  orange2: "#FF7A00",
  divider: "#A9AEB1",
  black: "#000000",
  black1: "#212529",
  black2: "#788796",
  black3: "#BDBEC8",
  black4: "#CACBD3",
  chart: {
    in: "#F5A623",
    out: "#E14960",
    inGlow: "#FAD291",
    outGlow: "#FE6F9A",
    cursor: "#FE9496",
    grid: "#BDBEC8",
  },
  table: {
    header: "#FEF3F6",
    divider: "#B7CCFB",
  },
  status: {
    online: "#14B8B4",
    offline: "#EF4337",
  },
  white: "#FFFFFF",
  white2: "#EDEFF9",
  white3: "#DBE4FF",
  white4: "#F9FAFF",
  yellow: "#FEBE54",
  yellow2: "#F1A322",
  yellow3: "#FFEBCC",
  alarm: "#F2695F",
  red: {
    light: "#FFDDDB",
  },
  red1: "#FAC7C3",
  red2: "#EA663F",
  green: {
    light: "#B8EAE9",
  },
  purple: "#AB5EED",
  bluegradient:
    "radial-gradient(141.42% 462.96% at 0% 0%, rgba(14, 126, 228, 0.10) 0%, rgba(14, 126, 228, 0.00) 100%)",
  greengradient:
    "radial-gradient(141.42% 462.13% at 0% 0%, rgba(20, 184, 180, 0.10) 0%, rgba(20, 184, 180, 0.00) 100%)",
  yellowgradient:
    "radial-gradient(141.42% 626.94% at 0% 0%, rgba(250, 210, 145, 0.20) 0%, rgba(250, 210, 145, 0.00) 98.96%)",
  orangegradient:
    "radial-gradient(141.42% 462.13% at 0% 0%, rgba(255, 122, 0, 0.10) 0%, rgba(255, 122, 0, 0.00) 100%)",
  redgradient:
    "radial-gradient(141.42% 626.94% at 0% 0%, rgba(234, 102, 63, 0.20) 0%, rgba(234, 102, 63, 0.00) 100%);",
};

export const SHADOW = {
  cardborder: "1px 3px 20px 0px rgba(154, 170, 207, 0.10)",
  smallCardborder: "1px 0px 19px 0px rgba(14, 126, 228, 0.10)",
  button: "1px 2px 6px 0px rgba(190, 196, 198, 0.20)",
};
