import { tokenState } from "../auth/tokenState";
import { usePersister } from "../utils/persister";
import { createContext, PropsWithChildren, useContext } from "react";

interface AuthContextValue {
  isLoggedIn: boolean;
}

const AuthContext = createContext<AuthContextValue>({
  isLoggedIn: false,
});

export function useAuthContext() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: PropsWithChildren<{}>) {
  const [refreshToken] = usePersister(tokenState.refreshToken);
  const isLoggedIn = !!refreshToken;

  return (
    <AuthContext.Provider value={{ isLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
}
