import { Dialog, DialogPanel } from "@headlessui/react";
import { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { httpClient } from "../../../api/http";
interface NewProjectModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  noteId: string;
  noteValue?: string | undefined;
  mutate: () => void;
}

export default function NoteModal({
  isOpen,
  setIsOpen,
  noteId,
  noteValue,
  mutate,
}: NewProjectModalProps) {
  const [noteData, setNoteData] = useState<any>();

  const handleSave = async () => {
    // post api with httpClient
    try {
      await httpClient.patch("/v1/projects/" + noteId, {
        note: noteData,
      });
    } catch (error) {
      alert(`Error: ${error}`);
    }
    setIsOpen(false);
    mutate();
  };

  useEffect(() => {
    const note = noteValue;
    if (note) {
      setNoteData(note);
    } else {
      setNoteData(undefined);
    }
  }, [noteValue]);

  return (
    <>
      <Dialog
        open={isOpen}
        as="div"
        className="relative z-10 focus:outline-none"
        onClose={() => setIsOpen(false)}
      >
        <div className="fixed inset-0 bg-black/25 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel
              transition
              className="rounded-[10px] bg-[#F9FAFF] border border-[#DBE4FF] w-[800px] h-fit p-4 shadow-cardborder backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
            >
              <div className="h-full flex flex-col justify-between p-0">
                <div className="flex items-center justify-between border-b border-[#EDEFF9] pb-2">
                  <p className="text-[18px] font-semibold text-primary-dark">
                    Note
                  </p>
                  <RxCross2
                    className="text-primary w-6 h-6 cursor-pointer"
                    onClick={() => setIsOpen(false)}
                  />
                </div>
                <div className="flex flex-col gap-2 mt-2 h-full">
                  <div className="flex items-center gap-4">
                    <textarea
                      value={noteData ?? ""}
                      className="border-2 border-[#DBE4FF] px-5 py-1.5 rounded-lg w-full h-40"
                      onChange={(e) => {
                        setNoteData(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-end">
                  <button
                    className="bg-primary text-white py-[6px] px-[20px] rounded-lg mt-4"
                    onClick={() => handleSave()} // clear state in this modal
                  >
                    Save
                  </button>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
