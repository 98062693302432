import { Dialog, DialogPanel } from "@headlessui/react";
import { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { httpClient } from "../../../api/http";
import { LoadingIndicator } from "../../../components/LoadingIndicator";
import { P } from "../../../components/Common/Typo/typo";
import { COLOR } from "../../../styles/constants";

interface NewProjectModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function NewProjectModal({
  isOpen,
  setIsOpen,
}: NewProjectModalProps) {
  const [loading, setLoading] = useState(false);

  const [projectName, setProjectName] = useState("");
  const [size, setSize] = useState("");
  const [roomNumber, setRoomNumber] = useState<number>();
  const [selectedProduct, setSelectedProduct] = useState<number>();
  const [selectedPackage, setSelectedPackage] = useState<number>();
  const [selectedApps, setSelectedApps] = useState<number[]>([]);
  const [selectedAppsName, setSelectedAppsName] = useState<string[]>([]);
  const navigate = useNavigate();

  const sizeButton = ["S", "M", "L"];

  const { data: productData } = useSWR([`/v1/products/`]);

  const handleSelectProduct = (productId: number) => {
    setSelectedProduct(productId);
    setSelectedPackage(undefined);
    setSelectedApps([]);
  };

  const handleSelectPackage = (packageId: number) => {
    setSelectedPackage(packageId);
    setSelectedApps(
      productData?.results
        ?.find((product: any) => product.id === selectedProduct)
        ?.related_packages?.find(
          (packageItem: any) => packageItem.id === packageId
        )?.apps ?? []
    );
  };

  const handleToggleAppSelection = (appId: number) => {
    if (selectedApps.includes(appId)) {
      setSelectedApps((prev) => prev.filter((app) => app !== appId));
    } else {
      setSelectedApps((prev) => [...prev, appId]);
    }
  };

  const handleCreateProject = async () => {
    if (!projectName || !selectedProduct) {
      alert("Please fill required fields");
      return;
    }

    setLoading(true);

    // post api with httpClient
    try {
      const response = await httpClient.post("/v1/projects/", {
        name: projectName,
        product_id: selectedProduct,
        package_id: selectedPackage,
        apps: selectedApps,
        data: {
          total_rooms: roomNumber,
          man_hour_saving:
            selectedApps.reduce((acc, appId) => {
              const app = productData?.results
                ?.find((product: any) => product.id === selectedProduct)
                ?.related_apps?.find((app: any) => app.id === appId);
              return acc + (app?.estimated_saving_hrs ?? 0);
            }, 0) * Math.min(5, Math.floor((roomNumber ?? 0) / 100) + 1),
          cost_per_hour: 180,
        },
        status: "chat",
      });
      await httpClient.post("/v1/gpt/", {
        project: response.data.data._id,
        room_number: roomNumber,
        apps: selectedAppsName,
      });
      setLoading(false);
      setIsOpen(false);
      navigate(`/admin/chat?project_id=${response.data.data._id}`);
    } catch (error) {
      setLoading(false);
      alert(`Error: ${error}`);
    }
  };

  useEffect(() => {
    setSelectedAppsName(
      productData?.results
        ?.find((product: any) => product.id === selectedProduct)
        ?.related_apps?.filter((app: any) => selectedApps.includes(app.id))
        .map((app: any) => app.name)
    );
  }, [selectedProduct, selectedApps]);

  const product = productData?.results?.find(
    (product: any) => product.id === selectedProduct
  );

  // Group related_apps by app_group
  const groupedApps = product?.related_apps?.reduce((acc: any, app: any) => {
    if (!acc[app.app_group]) {
      acc[app.app_group] = [];
    }
    acc[app.app_group].push(app);
    return acc;
  }, {});

  return (
    <>
      <Dialog
        open={isOpen}
        as="div"
        className="relative z-10 focus:outline-none"
        onClose={() => setIsOpen(false)}
      >
        <div className="fixed inset-0 bg-black/25 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel
              transition
              className="rounded-[10px] bg-[#F9FAFF] border border-[#DBE4FF] w-[800px] h-fit p-4 shadow-cardborder backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
              style={{ height: selectedProduct ? "90vh" : "fit-content" }}
            >
              <div className="h-full flex flex-col justify-between p-0">
                <div className="flex items-center justify-between border-b border-[#EDEFF9] pb-2">
                  <p className="text-[18px] font-semibold text-primary-dark">
                    Quotation Details
                  </p>
                  <RxCross2
                    className="text-primary w-6 h-6 cursor-pointer"
                    onClick={() => setIsOpen(false)}
                  />
                </div>
                <div className="flex flex-col gap-2 mt-2 h-full overflow-y-scroll">
                  <P fontSize="16px" fontWeight="600" color="#065BA9">
                    Property Details
                  </P>
                  <P color={COLOR.black2}>Input your name</P>
                  <div className="flex items-center gap-4">
                    <input
                      type="text"
                      className="border-2 border-[#DBE4FF] px-5 py-1.5 rounded-lg"
                      value={projectName}
                      onChange={(e) => {
                        setProjectName(e.target.value);
                      }}
                    />
                  </div>
                  <P color={COLOR.black2}>Select your type</P>
                  <div className="flex items-center gap-4">
                    {productData?.results
                      ?.sort((a: any, b: any) => a.id - b.id)
                      ?.map((product: any) => (
                        <button
                          key={product.id}
                          className={`border-2 px-5 py-1.5 rounded-lg
                          ${
                            selectedProduct === product.id
                              ? "bg-[#0E7EE4] text-white border-[#0E7EE4]"
                              : "bg-white text-[#065BA9] border-[#DBE4FF]"
                          }
                          `}
                          onClick={() => handleSelectProduct(product.id)}
                        >
                          <p className="text-xs font-medium capitalize">
                            {product.name}
                          </p>
                        </button>
                      ))}
                  </div>

                  {selectedProduct && (
                    <div className="flex flex-col gap-2">
                      <P color={COLOR.black2}>Select your size</P>
                      <div className="flex items-center gap-2">
                        {sizeButton.map((item) => (
                          <button
                            key={item}
                            className={`border-2 px-5 py-1.5 rounded-lg
                          ${
                            size === item
                              ? "bg-[#0E7EE4] text-white border-[#0E7EE4]"
                              : "bg-white text-[#065BA9] border-[#DBE4FF]"
                          }
                          `}
                            onClick={() => setSize(item)}
                          >
                            <p className="text-xs font-medium capitalize">
                              {item}
                            </p>
                          </button>
                        ))}
                      </div>
                      <P color={COLOR.black2}>Input your room number</P>
                      <div className="flex items-center gap-2 mb-2">
                        <input
                          type="number"
                          className="border-2 border-[#DBE4FF] px-5 py-1.5 rounded-lg"
                          value={roomNumber}
                          onChange={(e) => {
                            setRoomNumber(
                              e.target.value === ""
                                ? undefined
                                : Number(e.target.value)
                            );
                          }}
                        />
                      </div>
                      <P
                        fontSize="16px"
                        fontWeight="600"
                        color="#065BA9"
                        className="border-t border-[#EDEFF9] pt-2"
                      >
                        Service
                      </P>
                      <P color={COLOR.black2}>Select package</P>
                      <div className="flex items-center gap-2">
                        {productData?.results
                          ?.find(
                            (product: any) => product.id === selectedProduct
                          )
                          ?.related_packages?.sort(
                            (a: any, b: any) => a.id - b.id
                          )
                          ?.map((packageItem: any) => (
                            <div
                              key={packageItem.name}
                              className="flex items-center gap-2"
                            >
                              <button
                                className={`border-2 px-5 py-1.5 rounded-lg ${
                                  selectedPackage === packageItem.id
                                    ? "bg-[#0E7EE4] text-white border-[#0E7EE4]"
                                    : "bg-white text-[#065BA9] border-[#DBE4FF]"
                                }`}
                                onClick={() =>
                                  handleSelectPackage(packageItem.id)
                                }
                              >
                                <p className="text-xs font-medium capitalize">
                                  {packageItem.name}
                                </p>
                              </button>
                            </div>
                          ))}
                      </div>
                      <div className="flex flex-col gap-2">
                        {groupedApps &&
                          Object.keys(groupedApps).map((group) => (
                            <div key={group}>
                              <P
                                color={COLOR.black2}
                                className="capitalize mb-2"
                              >
                                {group}
                              </P>
                              <div className="flex items-center gap-2 flex-wrap">
                                {groupedApps[group].map((app: any) => (
                                  <button
                                    key={app.id}
                                    className={`border-2 px-5 py-1.5 rounded-lg
                                    ${
                                      selectedApps.includes(app.id)
                                        ? "bg-[#0E7EE4] text-white border-[#0E7EE4]"
                                        : "bg-white text-[#065BA9] border-[#DBE4FF]"
                                    }`}
                                    onClick={() =>
                                      handleToggleAppSelection(app.id)
                                    }
                                  >
                                    <p className="text-xs font-medium capitalize">
                                      {app.name}
                                    </p>
                                  </button>
                                ))}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex items-center justify-end">
                  <button
                    className="bg-primary text-white py-[6px] px-[20px] rounded-lg mt-4"
                    onClick={() => handleCreateProject()} // clear state in this modal
                  >
                    Create
                  </button>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      {loading && <LoadingIndicator />}
    </>
  );
}
