import React, { ReactNode } from "react";

interface ModalProps {
  children: ReactNode;
  showModal: boolean;
  width?: string;
  height?: string;
  onClose: () => void;
  overflow?: string;
}

const Modal: React.FC<ModalProps> = ({
  children,
  showModal,
  onClose,
  width,
  height,
  overflow,
}) => {
  return showModal ? (
    <div
      className="fixed top-0 left-0 flex w-screen h-screen bg-black/20 z-[100]"
      onClick={onClose}
    >
      <div
        className="relative rounded-[6px] bg-white m-auto p-[24px]"
        style={{
          width: width ?? "auto",
          height: height ?? "auto",
          maxHeight: "70%",
          overflow: overflow ?? "visible",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex overflow-hidden w-full h-full">
          <div className="w-full">{children}</div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
