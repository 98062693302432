import EventEmitter from "events";

import { Persister } from "./types";

export class LocalStoragePersister<T>
  extends EventEmitter
  implements Persister<T>
{
  constructor(private key: string) {
    super();
    window.addEventListener("storage", (e) => {
      if (e.key === this.key) {
        this.emit("change");
      }
    });
  }

  get(): T | null {
    const jsonValue = localStorage.getItem(this.key);
    if (jsonValue && jsonValue !== "undefined") {
      return JSON.parse(jsonValue);
    } else {
      return null;
    }
  }

  set(value: T | null): void {
    if (value !== null) {
      localStorage.setItem(this.key, JSON.stringify(value));
    } else {
      localStorage.removeItem(this.key);
    }
    this.emit("change");
  }

  subscribe(callback: (value: T | null) => void): () => void {
    const listener = () => callback(this.get());
    this.on("change", listener);
    return () => this.removeListener("change", listener);
  }
}
