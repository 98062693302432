import { ReactNode } from "react";

export interface ErrorPageProps {
  title: string;
  description?: string;
  children?: ReactNode;
}

const defaultDescription = "Please login again.";

export function ErrorPage({
  title,
  description = defaultDescription,
  children,
}: ErrorPageProps) {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center">
      <div>
        <h1 className="text-4xl text-black1 font-bold">{title}</h1>
        <p className="text-lg text-black2">{description}</p>
        {children && <div className="mt-8 flex gap-3">{children}</div>}
      </div>
    </div>
  );
}
