import { useEffect, useState } from 'react'

import { Persister } from './types'

export function usePersister<T>(persister: Persister<T>) {
  const [value, setValue] = useState<T | null>(persister.get())

  useEffect(() => {
    return persister.subscribe(setValue)
  }, [persister])

  return [value, persister.set]
}
