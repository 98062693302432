import ReactEcharts from "echarts-for-react";
import React from "react";

type ChartROIProps = {
  investmentCost: number;
  startDate: string;
  savingsCostPerYear: number;
  numberOfYears: number;
};

export const ChartROI: React.FC<ChartROIProps> = ({
  investmentCost,
  startDate,
  savingsCostPerYear,
  numberOfYears = 5,
}) => {
  const savingsCost = Array.from(
    { length: numberOfYears * 12 },
    (_, index) => savingsCostPerYear * (index / 12) - investmentCost
  ); // Savings cost per month to get the slope
  const months = Array.from({ length: numberOfYears * 12 }, (_, index) => {
    const date = new Date(startDate);
    date.setMonth(date.getMonth() + index);
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}`;
  });

  // Find the month of ROI
  const roiMonthIndex = savingsCost.findIndex((cost) => cost >= 0);
  const roiMonth = roiMonthIndex !== -1 ? months[roiMonthIndex] : "N/A";

  // Calculate duration from start date to ROI month
  const roiDate = new Date(startDate);
  roiDate.setMonth(roiDate.getMonth() + roiMonthIndex);

  const roiLabel =
    roiMonthIndex !== -1
      ? `Payback period : ${Math.floor(roiMonthIndex / 12)} year(s) ${
          roiMonthIndex % 12
        } month(s)`
      : "N/A";

  const options = {
    grid: { top: 8, right: 8, bottom: 24, left: 70 },
    xAxis: {
      type: "category",
      data: months,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "Savings Cost (Below 0)",
        data: savingsCost.map((value) => (value < 0 ? value : 0)),
        type: "line",
        smooth: true,
        showSymbol: false,
        areaStyle: {
          color: "red",
          opacity: 0.3,
        },
      },
      {
        name: "Savings Cost (Above 0)",
        data: savingsCost.map((value) => (value >= 0 ? value : 0)),
        type: "line",
        smooth: true,
        areaStyle: {
          color: "blue",
          opacity: 0.3,
        },
      },
      {
        name: "Savings Cost",
        data: savingsCost,
        type: "line",
        smooth: true,
        markPoint: {
          symbol: "triangle",
          symbolSize: 25,
          symbolRotate: 180,
          symbolOffset: [0, -15],
          label: {
            position: "top",
          },
          data: [
            {
              name: "ROI Month",
              coord: [roiMonth, 0],
              value: roiLabel,
              itemStyle: { color: "green" },
            },
          ],
        },
      },
    ],
    tooltip: {
      trigger: "axis",
    },
  };

  return <ReactEcharts option={options} style={{ height: "200px" }} />;
};
