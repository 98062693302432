import { httpClient } from "../http";
import { tokenState } from "../../auth";

export interface MeResponse {
  first_name: string;
  last_name: string;
  permissions: string[];
}

export interface VerifyResponse {
  data: any;
}

export async function verify() {
  const access = await tokenState.getAccessToken();
  return httpClient.post<VerifyResponse>("/v1/verify/", { token: access });
}

export const getUsers = async () => {
  return httpClient.get(`/v1/users/`);
};

export const getApprovedUsers = async () => {
  return httpClient.get(
    `https://experiencebackend.altotech.net/api/v0.3/get_user_list`
  );
};

export const removePendingUsers = async (lineId: string, role: string) => {
  return httpClient.post(
    `https://experiencebackend.altotech.net/api/v0.3/discard_pending_user`,
    { lineId, role }
  );
};

export const removeUsers = async (lineId: string, role: string) => {
  return httpClient.post(
    `https://experiencebackend.altotech.net/api/v0.3/remove_user`,
    { lineId, role }
  );
};

export const acceptUsers = async (lineId: string, role: string) => {
  return httpClient.post(
    `https://experiencebackend.altotech.net/api/v0.3/accept_pending_user`,
    { lineId, role }
  );
};

export const getPendingLineUsers = async () => {
  return httpClient.get(`/v1/line_users/`);
};

export const sendLineMessage = async (text: string, location: any) => {
  return httpClient.post(
    `https://experiencebackend.altotech.net/api/v1/linebot_push`,
    { text, location }
  );
};

export const createNewUser = async (payload: any) => {
  return httpClient.post(`/v1/register/`, payload);
};

export const deleteUser = async (id: number) => {
  return httpClient.delete(`/v1/users/${id}/`);
};

export const approveUser = async (id: number) => {
  return httpClient.patch(`/v1/line_users/${id}/`, {
    status: "approved",
  });
};

export const rejectUser = async (id: number) => {
  return httpClient.patch(`/v1/line_users/${id}/`, {
    status: "rejected",
  });
};
