import axios from "axios";
import {
  interceptAutoRefreshAuthToken,
  interceptInsertAuthToken,
} from "./interceptors";

import {
  GetEndpoints,
  PostEndpoints,
  PutEndpoints,
  DeleteEndpoints,
} from "./types";

export let apiUrl =
  "https://proposal-builder-app-backend-hqg0ajgcaqgsdff9.southeastasia-01.azurewebsites.net/api";

if (typeof window !== "undefined") {
  const base = window.location.hostname;
  if (base !== "proposalbuilder.altotech.net") {
    apiUrl =
      "https://proposal-builder-app-backend-staging-dphcazc3e0gbhyd0.southeastasia-01.azurewebsites.net/api";
  }
}

// if (typeof window !== "undefined") {
//   const base = window.location.hostname;
//   if (base === "proud-sky-0c30c8600.5.azurestaticapps.net") {
//     apiUrl = "https://alto-auto-certificate-backend-dev.azurewebsites.net/api";
//   } else if (base !== "auto-certification.altotech.net") {
//     apiUrl =
//       "https://alto-auto-certificate-backend-staging.azurewebsites.net/api";
//   }
// }

export const httpClient = axios.create({ baseURL: apiUrl });
interceptInsertAuthToken(httpClient);
interceptAutoRefreshAuthToken(httpClient);

export async function get<Path extends keyof GetEndpoints>(path: Path) {
  const response = await httpClient.get<GetEndpoints[Path]["responseBody"]>(
    path
  );
  return response.data;
}

export async function post<Path extends keyof PostEndpoints>(
  path: Path,
  body: PostEndpoints[Path]["requestBody"],
  asForm: boolean = false
) {
  const response = await httpClient.post<PostEndpoints[Path]["responseBody"]>(
    path,
    transformBody(body, asForm)
  );
  return response.data;
}

export async function put<Path extends keyof PutEndpoints>(
  path: Path,
  body: PutEndpoints[Path]["requestBody"]
) {
  const response = await httpClient.put<PutEndpoints[Path]["responseBody"]>(
    path,
    body
  );
  return response.data;
}

export async function callDelete<Path extends keyof DeleteEndpoints>(
  path: Path
) {
  const response = await httpClient.delete<
    DeleteEndpoints[Path]["responseBody"]
  >(path);
  return response.data;
}

function transformBody(body: any, asForm: boolean) {
  if (asForm) {
    return new URLSearchParams(body);
  }
  return body;
}

export async function swrFetcher(args: any[]) {
  const [path, params] = args;
  const response = await httpClient.get(path, { params });
  return response.data;
}
