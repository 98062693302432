import { useLocation, useNavigate } from "react-router-dom";
import { PageWrapper } from "../../layouts/admin/PageWrapper";
import { P } from "../../components/Common/Typo/typo";
import OverviewFlow from "./components/diagram/App";
import { FaCalendar } from "react-icons/fa";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { Switch } from "@headlessui/react";
import TableBOQ from "../cartInterface/components/TableBOQ";
import { DataItem, Section } from "../cartInterface";
import { ChartROI } from "../../components/charts/roi";
import dayjs from "dayjs";

import { nodes } from "./components/diagram/initial-elements";
import { COLOR } from "../../styles/constants";

import { usePDF } from "react-to-pdf";

const SummaryItem = ({
  title,
  value,
  unit,
}: {
  title: string;
  value: number;
  unit?: string;
}) => {
  return (
    <div className="flex justify-between p-2 rounded-lg border border-gray-200">
      <span>{title}</span>
      <div className="flex items-center justify-center gap-2">
        <span>
          {value
            ? value.toLocaleString(undefined, { maximumFractionDigits: 2 })
            : 0}
        </span>
        {unit && <span>{unit}</span>}
      </div>
    </div>
  );
};

const TimeDetails = ({ dataTableTimeUseState }: any) => {
  return (
    <div className="mt-2 flex items-stretch justify-center gap-2 p-1 border border-gray-200 rounded-lg">
      {dataTableTimeUseState?.map((item: any) => (
        <div className="flex flex-col items-center gap-2 w-full shadow-lg min-h-full">
          <div className="flex items-center w-full gap-2 p-2">
            <div className="border p-2 rounded-full bg-primary-light">
              <FaCalendar />
            </div>
            <div className="border-2 w-full h-0"></div>
          </div>
          <div className="px-6 pb-10 flex flex-col gap-2 h-full">
            <P fontSize="16px" fontWeight="200">
              {item?.duration} {item?.unit_type}
              {item?.duration && item?.unit_type && item.duration > 1
                ? "s"
                : ""}
            </P>
            <P fontSize="20px" fontWeight="700">
              {item?.phase_name}
            </P>
            <P fontSize="16px" fontWeight="300">
              {item?.detail}
            </P>
          </div>
        </div>
      ))}
    </div>
  );
};

const Result = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get("project_id");

  const { data: project } = useSWR([`/v1/projects/${projectId}/`]);

  const [dataTableTimeUseState, setDataTableTimeUseState] = useState([]);

  useEffect(() => {
    if (project) {
      const data = project?.data?.timeline ?? [];
      const sortedData = data.sort((a: any, b: any) =>
        !b?.order ? -1 : !a?.order ? 1 : a.order - b.order
      );
      setDataTableTimeUseState(sortedData);
    }
  }, [project]);

  const { toPDF, targetRef } = usePDF({
    filename: `BOQ-${project?.data?.name}-${dayjs().format("DD-MM-YYYY")}.pdf`,
  });

  const [data, setData] = useState<DataItem[]>([]);

  const { data: project_boq } = useSWR([
    "/v1/project_boq/",
    { project: projectId },
  ]);

  useEffect(() => {
    if (project_boq?.data) {
      setData(project_boq.data);
    }
  }, [project_boq]);

  const capexData = Array.isArray(data)
    ? data.find((item) => item.type === "CAPEX")?.section || []
    : [];

  const capexCost = capexData.reduce((acc: any, section: any) => {
    return (
      acc +
      section.items.reduce(
        (acc: any, item: any) => acc + item.quantity * item.unit_rate,
        0
      )
    );
  }, 0);

  const opexData = Array.isArray(data)
    ? data.find((item) => item.type === "OPEX")?.section || []
    : [];

  const opexCost = opexData.reduce((acc: any, section: any) => {
    return (
      acc +
      section.items.reduce(
        (acc: any, item: any) => acc + item.quantity * item.unit_rate,
        0
      )
    );
  }, 0);

  const [isIncludeUpside, setIsIncludeUpside] = useState(false);

  const [profitSummary, setProfitSummary] = useState<any>([]);

  useEffect(() => {
    setProfitSummary([
      {
        key: "1",
        title: "Energy Saving / Year",
        value:
          (project?.data?.data.yearly_electricity_bill *
            project?.data?.data.estimated_yearly_savings_percentage) /
          100,
        unit: "THB",
      },
      {
        key: "2",
        title: "Man-Hour Saving / Year (THB)",
        value:
          project?.data?.data?.man_hour_saving *
          project?.data?.data?.cost_per_hour,
        unit: "THB",
      },
      {
        key: "3",
        title: "Man-Hour Saving / Year (Hrs)",
        value: project?.data?.data?.man_hour_saving,
        unit: "Hrs",
      },
      {
        key: "4",
        title: "CO2 Reduction / Year",
        value:
          ((project?.data?.data.yearly_electricity_bill *
            project?.data?.data.estimated_yearly_savings_percentage) /
            100 /
            5) *
          0.4999,
        unit: "kg CO2e / kWh",
      },
      {
        key: "5",
        title: "Value Upside Opportunity",
        // {ค่าห้อง} * {percent ราคาที่ขึ้นได้จากการใช้ alto} * {จำนวนห้อง} * {occupancy rate} * 365
        value:
          (project?.data?.data.price_per_night *
            project?.data?.data.upside_percentage *
            project?.data?.data.total_rooms *
            project?.data?.data.occupancy_rate *
            365) /
          10000,
        unit: "THB",
      },
    ]);
  }, [project]);

  return (
    <PageWrapper title="BOQ Summary">
      <div className="flex flex-col mx-[-16px] h-[95%] overflow-y-scroll">
        <div ref={targetRef} className="relative flex flex-col px-4">
          <div className="flex gap-[16px] mb-[4px] items-center">
            <p className="text-[32px] font-semibold text-primary">
              {project?.data?.name}
            </p>
            <p className="text-[16px]">Date : {dayjs().format("DD/MM/YYYY")}</p>
          </div>
          <div className="flex flex-col gap-[36px] mb-10">
            <div className="grid grid-cols-12 gap-2">
              <div className="flex flex-col gap-[16px] col-span-8">
                <div>
                  <h2 className="text-xl font-semibold mb-4">CAPEX</h2>
                  <div className="flex flex-col gap-[12px]">
                    <TableBOQ
                      data={capexData}
                      setData={(updatedCapex: Section[]) => {
                        if (Array.isArray(data)) {
                          const updatedData = data.map((item) =>
                            item.type === "CAPEX"
                              ? { ...item, section: updatedCapex }
                              : item
                          );
                          setData(updatedData);
                        }
                      }}
                      editable={false}
                    />
                    <P
                      fontSize="16px"
                      fontWeight="600"
                      color={COLOR.black}
                      align="right"
                    >
                      Total {capexCost?.toLocaleString()} THB
                    </P>
                  </div>
                  <h2 className="text-xl font-semibold mt-8 mb-4">OPEX</h2>
                  <div className="flex flex-col gap-[12px]">
                    <TableBOQ
                      data={opexData}
                      setData={(updatedOpex: Section[]) => {
                        if (Array.isArray(data)) {
                          const updatedData = data.map((item) =>
                            item.type === "OPEX"
                              ? { ...item, section: updatedOpex }
                              : item
                          );
                          setData(updatedData);
                        }
                      }}
                      editable={false}
                    />
                    <P
                      fontSize="16px"
                      fontWeight="600"
                      color={COLOR.black}
                      align="right"
                    >
                      Total {opexCost?.toLocaleString()} THB
                    </P>
                  </div>
                </div>
                <div className="flex justify-end items-center bg-white border border-white3 shadow-badgelogout rounded-[12px] h-[64px] py-[20px] px-[28px] gap-[20px]">
                  <P fontSize="16px" color={COLOR.black1}>
                    Grand Total
                  </P>
                  {project?.data?.discount ? (
                    <P
                      fontSize="18px"
                      color={COLOR.black1}
                      className="line-through"
                    >
                      {(opexCost + capexCost)?.toLocaleString()}
                    </P>
                  ) : null}
                  <P fontSize="18px" fontWeight="600" color={COLOR.black1}>
                    {(
                      (capexCost * (100 - (project?.data?.discount ?? 0))) /
                        100 +
                      opexCost
                    )?.toLocaleString()}{" "}
                    THB
                  </P>
                </div>
              </div>
              <div className="col-span-4 p-5 border border-gray-200 rounded-lg bg-gray-50 max-h-fit h-fit">
                <h2 className="text-blue-600 text-lg mb-5">
                  Profitability Summary
                </h2>
                {profitSummary?.map((item: any) => (
                  <div className="mb-2">
                    <SummaryItem
                      key={item.title}
                      title={item.title}
                      value={item.value}
                      unit={item.unit}
                    />
                  </div>
                ))}

                <div className="my-5">
                  <div className="flex justify-between">
                    <h3 className="text-gray-600 text-base mb-2">
                      Return of Investment
                    </h3>
                    <div className="flex items-center gap-2">
                      <Switch
                        checked={isIncludeUpside}
                        onChange={(value: boolean) => setIsIncludeUpside(value)}
                      >
                        {({ checked }) => (
                          <button
                            className={`${
                              checked ? "bg-primary " : "bg-black2"
                            } relative inline-flex h-[20px] w-[36px] items-center rounded-full`}
                          >
                            <span
                              className={`${
                                checked
                                  ? "translate-x-[18px]"
                                  : "translate-x-[2px]"
                              } inline-block h-[16px] w-[16px] transform rounded-full bg-white transition`}
                            />
                          </button>
                        )}
                      </Switch>
                      <P fontSize="16px" fontWeight="400">
                        Include Upside
                      </P>
                    </div>
                  </div>
                  <ChartROI
                    investmentCost={
                      (capexCost * (100 - project?.data?.discount)) / 100
                    }
                    startDate={dayjs().toString()}
                    savingsCostPerYear={
                      isIncludeUpside
                        ? (project?.data?.data.yearly_electricity_bill *
                            project?.data?.data
                              .estimated_yearly_savings_percentage) /
                            100 +
                          (project?.data?.data.price_per_night *
                            project?.data?.data.upside_percentage *
                            project?.data?.data.total_rooms *
                            project?.data?.data.occupancy_rate *
                            365) /
                            10000 +
                          project?.data?.data?.man_hour_saving *
                            project?.data?.data?.cost_per_hour -
                          opexCost
                        : (project?.data?.data.yearly_electricity_bill *
                            project?.data?.data
                              .estimated_yearly_savings_percentage) /
                            100 +
                          project?.data?.data?.man_hour_saving *
                            project?.data?.data?.cost_per_hour -
                          opexCost
                    }
                    numberOfYears={5}
                  />
                </div>
              </div>
            </div>
            <p className="text-[32px] font-semibold text-primary">Timeline</p>
            <TimeDetails dataTableTimeUseState={dataTableTimeUseState} />
            <p className="text-[32px] font-semibold text-primary">
              Device Diagram
            </p>
            <div className="min-h-[500px] w-full border-2 rounded-lg border-blue-500">
              <OverviewFlow
                nodesData={nodes?.map((node) => {
                  if (node.id === "apps") {
                    return {
                      ...node,
                      data: {
                        ...node.data,
                        items: project?.data?.apps?.map(
                          (item: any) => item?.name
                        ),
                      },
                    };
                  } else if (node.id === "guest-rooms") {
                    return {
                      ...node,
                      data: {
                        ...node.data,
                        items: capexData?.reduce(
                          (acc: any[], section: Section) => {
                            return [
                              ...acc,
                              ...(section?.items
                                ?.filter(
                                  (item) => item.location === "Guest Rooms"
                                )
                                ?.map((item) => ({
                                  name: item?.item_name,
                                  quantity: item?.quantity,
                                })) ?? []),
                            ];
                          },
                          []
                        ),
                      },
                    };
                  }
                  return node;
                })}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2 flex items-center justify-center gap-2">
        <button
          className="bg-primary px-[20px] py-[6px] rounded-lg"
          onClick={() => navigate(`/admin/cart/?project_id=${projectId}`)}
        >
          <P fontSize="14px" fontWeight="600" color="#FFF">
            Prev
          </P>
        </button>
        <button
          className="bg-primary px-[20px] py-[6px] rounded-lg"
          onClick={() => toPDF()}
        >
          <P fontSize="14px" fontWeight="600" color="#FFF">
            Download Result
          </P>
        </button>
      </div>
    </PageWrapper>
  );
};

export default Result;
