import { PageWrapper } from "../../layouts/admin/PageWrapper";
import { P } from "../../components/Common/Typo/typo";
import { COLOR } from "../../styles/constants";
import { useLocation, useNavigate } from "react-router-dom";
import useSWR from "swr";
import { httpClient } from "../../api/http";
import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Modal from "../../components/elements/Modal";
import { UserBadgeIcon } from "../../icons/UserBadgeIcon";
import BeatLoader from "react-spinners/BeatLoader";
import logo from "../../assets/logobackground.png";

const CartList = ({
  item,
  tooltipkey,
  setModalContent,
  setIsModalOpen,
}: any) => {
  return (
    <div
      className="grid grid-cols-12 gap-2 border border-[#EDEFF9] bg-[#FFF]/80 rounded-xl p-4 my-2 hover:border hover:border-primary hover:cursor-pointer"
      data-tooltip-id={tooltipkey}
      key={tooltipkey}
      onClick={() => {
        setModalContent(
          <div className="flex flex-1 mx-2 max-w-2xl space-x-4">
            <div className="flex flex-1 justify-center items-center mx-1">
              <img
                src={item.image_url || logo}
                alt="Marker"
                className="rounded-xl w-60 h-60 border border-[#065BA9] p-2"
              />
            </div>
            <div style={{ flex: 1 }}>
              <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                {item.site_name}
              </p>
              <p>{item.address}</p>
              <br></br>
              <p>
                <strong>{item.item_name || ""}</strong>
              </p>
              <p>
                {item.description
                  ? item.description
                      .split("\n")
                      .map((line: string, index: number) => (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      ))
                  : ""}
              </p>
            </div>
          </div>
        );
        setIsModalOpen(true);
      }}
    >
      <div className="col-span-7">
        <P
          fontSize="14px"
          fontWeight="400"
          color={COLOR.primary.dark}
          className="truncate"
        >
          {item.item_name}
        </P>
      </div>
      <div className="col-span-2">
        <P fontSize="14px" fontWeight="400" color={COLOR.primary.dark}>
          x {item.quantity}
        </P>
      </div>
      <div className="col-span-3">
        <P
          fontSize="14px"
          fontWeight="600"
          color={COLOR.primary.dark}
          className=" truncate"
        >
          {(item.unit_rate * item.quantity).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}{" "}
          THB
        </P>
      </div>
    </div>
  );
};

const CartBox = ({ boq, setModalContent, setIsModalOpen }: any) => {
  const sectionData = boq?.section;

  return (
    <div className="flex flex-col border border-[#EDEFF9] bg-[#FFF]/80 rounded-xl p-4 mt-2 max-h-[33vh] overflow-y-scroll">
      {sectionData?.map((section: any, section_index: number) => (
        <div key={section_index}>
          <P fontSize="18px" fontWeight="600" color={COLOR.primary.dark}>
            {section.section_name}
          </P>
          {section?.items?.map((item: any, item_index: number) => (
            <CartList
              key={item_index}
              item={item}
              setModalContent={setModalContent}
              setIsModalOpen={setIsModalOpen}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

const ChatInterface = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get("project_id");

  const [loading, setLoading] = useState(false);

  const [prompt, setPrompt] = useState("");

  const [projectStatus, setProjectStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null);

  const { data: project_boq, mutate: boq_mutate } = useSWR([
    "/v1/project_boq/",
    { project: projectId },
  ]);

  const { data: gptChat, mutate: gpt_mutate } = useSWR([
    `/v1/gpt/${projectId}/`,
  ]);

  const { data: project, mutate: project_mutate } = useSWR([
    `/v1/projects/${projectId}/`,
  ]);

  const [localGptChat, setLocalGptChat] = useState<any>([]);

  useEffect(() => {
    setProjectStatus(project?.data?.status);
  }, [project]);

  useEffect(() => {
    if (gptChat) {
      setLocalGptChat(gptChat);
    }
  }, [gptChat]);

  const gptLoadingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (gptLoadingRef.current) {
      gptLoadingRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loading]);

  const updateBoq = async () => {
    const promptString = prompt;
    setPrompt("");
    setLocalGptChat([...localGptChat, { role: "user", message: promptString }]);
    try {
      setLoading(true);
      await httpClient.patch(`/v1/gpt/${projectId}/`, {
        prompt: promptString,
      });
      await boq_mutate();
      await gpt_mutate();
      setLoading(false);
    } catch (error) {
      alert(`Error: ${error}`);
      setLoading(false);
    }
    if (projectStatus != "chat") {
      await httpClient.patch(`/v1/projects/${projectId}/`, {
        status: "chat",
      });
      await project_mutate();
    }
  };

  const calculateTotalPrice = () => {
    let total = 0;

    project_boq?.data.forEach((entry: any) => {
      entry.section.forEach((section: any) => {
        section.items.forEach((item: any) => {
          total += item.quantity * item.unit_rate;
        });
      });
    });

    return total;
  };

  return (
    <PageWrapper title="Alto BOQ Builder">
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-8 w-full border border-[#EDEFF9] bg-[#FFF]/80 rounded-xl h-[calc(100vh-120px)] p-4">
          <P fontSize="18px" fontWeight="600" color={COLOR.primary.dark}>
            Alto GPT
          </P>
          <div className="flex flex-col justify-between h-[calc(100vh-200px)] mt-3">
            <div className="h-full overflow-y-auto">
              {localGptChat?.map((chat: any, index: number) => (
                <div className={`flex items-center mt-2`} key={index}>
                  {chat.role === "user" && (
                    <div>
                      <UserBadgeIcon />
                    </div>
                  )}
                  <div className="text-white p-2 rounded-xl">
                    {chat.role === "assistant" && (
                      <button className="flex items-center gap-2 text-blue-500 bg-blue-50 rounded-md px-3 py-2 mb-2">
                        Alto GPT
                      </button>
                    )}
                    <div className="text-[16px] text-black whitespace-pre-line ">
                      {chat.message}
                    </div>
                    {chat.role === "assistant" && (
                      <hr className="border-gray-300 w-full mt-4" />
                    )}
                  </div>
                </div>
              ))}
              {loading && (
                <>
                  <div
                    className="flex flex-row items-center gap-2"
                    ref={gptLoadingRef}
                  >
                    <button className="flex items-center gap-2 text-blue-500 bg-blue-50 rounded-md px-3 py-2 mb-2">
                      Alto GPT
                    </button>
                    <BeatLoader color={"#4e8fff"} />
                  </div>
                  <div className="mb-4">
                    <Skeleton count={6} />
                  </div>
                </>
              )}
            </div>

            <div>
              <input
                className="mt-2 border border-[#EDEFF9] bg-[#000]/10 rounded-xl h-[80px] pl-4  pt-2.5  pr-2.5  pb-2.5 w-full placeholder:text-center"
                placeholder="How Can I help you today?"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    updateBoq();
                  }
                }}
              ></input>
              <div className="flex items-center justify-end">
                <button
                  className="bg-primary hover:bg-primary-dark text-white font-medium py-[6px] px-3 rounded-md flex items-center gap-2 shadow-buttoncard mt-2"
                  onClick={updateBoq}
                >
                  Enter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-4 w-full h-fit border border-[#EDEFF9] bg-[#FFF]/80 rounded-xl max-h-[calc(100vh-120px)] p-4 flex flex-col">
          <div className="h-full overflow-hidden">
            <P fontSize="18px" fontWeight="600" color={COLOR.primary.dark}>
              BOQ List
            </P>
            {project_boq?.data?.map((entry: any, index: number) => (
              <div className="mt-1" key={index}>
                <P fontSize="16px" fontWeight="600" color="#000">
                  {entry.type}
                </P>
                {loading ? (
                  <Skeleton count={10} />
                ) : (
                  <CartBox
                    boq={entry}
                    setModalContent={setModalContent}
                    setIsModalOpen={setIsModalOpen}
                  />
                )}
              </div>
            ))}
          </div>
          <div className="flex items-center justify-between gap-2 mt-4">
            <P fontSize="18px" fontWeight="6000">
              Grand Total
            </P>
            <div className="flex items-center justify-center gap-2">
              {loading ? (
                <Skeleton width={150} height={20} />
              ) : (
                <P fontSize="18px" fontWeight="6000">
                  {calculateTotalPrice().toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}
                </P>
              )}
              <p>THB</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2 flex items-center justify-center gap-2">
        <button
          className="bg-primary px-[20px] py-[6px] rounded-lg"
          onClick={() => navigate("/admin/overview")}
        >
          <P fontSize="14px" fontWeight="600" color="#FFF">
            Prev
          </P>
        </button>
        <button
          className="bg-primary px-[20px] py-[6px] rounded-lg"
          onClick={async () => {
            await httpClient.patch(`/v1/projects/${projectId}/`, {
              status: "cart",
            });
            navigate(`/admin/cart/?project_id=${projectId}`);
          }}
        >
          <P fontSize="14px" fontWeight="600" color="#FFF">
            Next
          </P>
        </button>
      </div>
      <Modal showModal={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {modalContent}
      </Modal>
    </PageWrapper>
  );
};

export default ChatInterface;
