import { P } from "../../../components/Common/Typo/typo";
import { COLOR } from "../../../styles/constants";

const HeaderTab = ({ selectedTab, setSelectedTab }: any) => {
  return (
    <div className="flex items-center gap-2">
      <div
        onClick={() => setSelectedTab("price")}
        className="cursor-pointer w-full"
      >
        <P
          fontSize="18px"
          fontWeight={selectedTab === "price" ? "600" : "400"}
          color={selectedTab === "price" ? COLOR.primary.DEFAULT : ""}
          className={
            selectedTab === "price"
              ? "border-b-2 border-primary text-center"
              : "text-center"
          }
        >
          Price
        </P>
      </div>
      <div
        onClick={() => setSelectedTab("time")}
        className="cursor-pointer w-full"
      >
        <P
          fontSize="18px"
          fontWeight={selectedTab === "time" ? "600" : "400"}
          color={selectedTab === "time" ? COLOR.primary.DEFAULT : ""}
          className={
            selectedTab === "time"
              ? "border-b-2 border-primary text-center"
              : "text-center"
          }
        >
          Time
        </P>
      </div>
      <div
        onClick={() => setSelectedTab("saving_roi")}
        className="cursor-pointer w-full"
      >
        <P
          fontSize="18px"
          fontWeight={selectedTab === "saving_roi" ? "600" : "400"}
          color={selectedTab === "saving_roi" ? COLOR.primary.DEFAULT : ""}
          className={
            selectedTab === "saving_roi"
              ? "border-b-2 border-primary text-center"
              : "text-center"
          }
        >
          Saving/ROI
        </P>
      </div>
    </div>
  );
};

export default HeaderTab;
