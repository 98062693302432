export function sharedPromise<Args extends readonly unknown[], R>(
  func: (...args: Args) => Promise<R>
): (...args: Args) => Promise<R> {
  let promise: Promise<R> | null = null;
  return (...args: Args) => {
    if (promise === null) {
      promise = func(...args);
      promise.then(
        () => (promise = null),
        () => (promise = null)
      );
    }
    return promise;
  };
}
