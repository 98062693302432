import { httpClient } from "../http";
import { useEffect, useState } from "react";

export interface MeProps {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  role: { id: number; name: string }[];
}

export const getMe: any = async (): Promise<MeProps> => {
  return httpClient.get(`/v1/me/`);
};

export const useMe = (params: any = {}) => {
  const [data, setData] = useState<MeProps | undefined>();
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const mutate = async () => {
    setIsLoading(true);
    try {
      const { data } = await httpClient.get(`/v1/me/`, { params });
      setData(data);
    } catch (err) {
      setError(err);
    }
    setIsLoading(false);
  };

  // Create loop to call function every 5 secs
  useEffect(() => {
    mutate();
  }, []);

  return {
    data,
    error,
    isLoading,
    mutate,
  };
};
