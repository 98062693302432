import { useEffect, useState } from "react";
import { ChartROI } from "../../../components/charts/roi";
import { P } from "../../../components/Common/Typo/typo";
import { Switch } from "@headlessui/react";
import dayjs from "dayjs";

const SummaryItem = ({
  title,
  value,
  unit,
}: {
  title: string;
  value: number;
  unit?: string;
}) => {
  return (
    <div className="flex justify-between p-2 rounded-lg border border-gray-200">
      <span>{title}</span>
      <div className="flex items-center justify-center gap-2">
        <span>
          {value
            ? value.toLocaleString(undefined, { maximumFractionDigits: 2 })
            : 0}
        </span>
        {unit && <span>{unit}</span>}
      </div>
    </div>
  );
};

const SavingDetails = ({
  data,
  setData,
  project,
  projectBOQ,
  discount,
}: {
  data: any;
  setData: any;
  project: any;
  projectBOQ: any;
  discount?: number;
}) => {
  const [profitSummary, setProfitSummary] = useState<any>([]);
  const formData = [
    {
      title: "Yearly Electricity Bill",
      key: "yearly_electricity_bill",
      unit: "THB",
    },
    {
      title: "Estimated Yearly Savings Percentage",
      key: "estimated_yearly_savings_percentage",
      unit: "%",
    },
    { title: "Total Rooms", key: "total_rooms", unit: "Rooms" },
    { title: "Price Per Night", key: "price_per_night", unit: "THB" },
    { title: "Occupancy Rate", key: "occupancy_rate", unit: "%" },
    { title: "Upside Percentage", key: "upside_percentage", unit: "%" },
    { title: "Man-Hour Saving", key: "man_hour_saving", unit: "Hrs" },
    { title: "Cost Per Hour", key: "cost_per_hour", unit: "THB" },
  ];

  useEffect(() => {
    setProfitSummary([
      {
        key: "1",
        title: "Energy Saving / Year",
        value:
          (data.yearly_electricity_bill *
            data.estimated_yearly_savings_percentage) /
          100,
        unit: "THB",
      },
      {
        key: "2",
        title: "Man-Hour Saving / Year (THB)",
        value: data.man_hour_saving * data.cost_per_hour,
        unit: "THB",
      },
      {
        key: "3",
        title: "Man-Hour Saving / Year (Hrs)",
        value: data.man_hour_saving,
        unit: "Hrs",
      },
      {
        key: "4",
        title: "CO2 Reduction / Year",
        value:
          ((data.yearly_electricity_bill *
            data.estimated_yearly_savings_percentage) /
            100 /
            5) *
          0.4999,
        unit: "kg CO2e / kWh",
      },
      {
        key: "5",
        title: "Value Upside Opportunity",
        // {ค่าห้อง} * {percent ราคาที่ขึ้นได้จากการใช้ alto} * {จำนวนห้อง} * {occupancy rate} * 365
        value:
          (data.price_per_night *
            data.upside_percentage *
            data.total_rooms *
            data.occupancy_rate *
            365) /
          10000,
        unit: "THB",
      },
    ]);
  }, [data, project]);

  const [isIncludeUpside, setIsIncludeUpside] = useState(false);

  const capexData = Array.isArray(projectBOQ)
    ? projectBOQ.find((item) => item.type === "CAPEX")?.section || []
    : [];

  const capexCost = capexData.reduce((acc: any, section: any) => {
    return (
      acc +
      section.items.reduce(
        (acc: any, item: any) => acc + item.quantity * item.unit_rate,
        0
      )
    );
  }, 0);

  const opexData = Array.isArray(projectBOQ)
    ? projectBOQ.find((item) => item.type === "OPEX")?.section || []
    : [];

  const opexCost = opexData.reduce((acc: any, section: any) => {
    return (
      acc +
      section.items.reduce(
        (acc: any, item: any) => acc + item.quantity * item.unit_rate,
        0
      )
    );
  }, 0);

  return (
    <div className="grid grid-cols-12 gap-2 mt-4">
      <div className="col-span-7">
        <P fontSize="18px" fontWeight="600">
          ROI Calculator
        </P>
        <div className="mt-2 w-full border border-[#EDEFF9] bg-[#FFF]/80 rounded-xl p-4">
          {formData.map((item) => (
            <div className="grid grid-cols-6 p-2 rounded-lg">
              <P
                fontSize="16px"
                fontWeight="400"
                color="#6B7280"
                className="col-span-4"
              >
                {item.title}
              </P>
              <div className="col-span-2 flex items-center justify-start gap-4">
                <input
                  type="number"
                  className="h-9 w-[200px] rounded-lg px-2 py-0 border border-primary-light col-span-2"
                  placeholder="0"
                  value={data?.[item.key]}
                  onChange={(e) => {
                    setData((prev: any) => {
                      return {
                        ...prev,
                        [item.key]:
                          e.target.value !== ""
                            ? Number(e.target.value)
                            : undefined,
                      };
                    });
                  }}
                />
                <P
                  fontSize="16px"
                  fontWeight="400"
                  color="#6B7280"
                  className="col-span-1"
                >
                  {item.unit}
                </P>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="col-span-5 p-5 border border-gray-200 rounded-lg bg-gray-50">
        <h2 className="text-blue-600 text-lg mb-5">Profitability Summary</h2>
        {profitSummary?.map((item: any) => (
          <div className="mb-2">
            <SummaryItem
              key={item.title}
              title={item.title}
              value={item.value}
              unit={item.unit}
            />
          </div>
        ))}

        <div className="my-5">
          <div className="flex justify-between">
            <h3 className="text-gray-600 text-base mb-2">
              Return of Investment
            </h3>
            <div className="flex items-center gap-2">
              <Switch
                checked={isIncludeUpside}
                onChange={(value: boolean) => setIsIncludeUpside(value)}
              >
                {({ checked }) => (
                  <button
                    className={`${
                      checked ? "bg-primary " : "bg-black2"
                    } relative inline-flex h-[20px] w-[36px] items-center rounded-full`}
                  >
                    <span
                      className={`${
                        checked ? "translate-x-[18px]" : "translate-x-[2px]"
                      } inline-block h-[16px] w-[16px] transform rounded-full bg-white transition`}
                    />
                  </button>
                )}
              </Switch>
              <P fontSize="16px" fontWeight="400">
                Include Upside
              </P>
            </div>
          </div>
          <ChartROI
            investmentCost={(capexCost * (100 - (discount ?? 0))) / 100}
            startDate={dayjs().toString()}
            savingsCostPerYear={
              isIncludeUpside
                ? (data.yearly_electricity_bill *
                    data.estimated_yearly_savings_percentage) /
                    100 +
                  (data.price_per_night *
                    data.upside_percentage *
                    data.total_rooms *
                    data.occupancy_rate *
                    365) /
                    10000 +
                  data.man_hour_saving * data.cost_per_hour -
                  opexCost
                : (data.yearly_electricity_bill *
                    data.estimated_yearly_savings_percentage) /
                    100 +
                  data.man_hour_saving * data.cost_per_hour -
                  opexCost
            }
            numberOfYears={5}
          />
        </div>
        {/* <div className="mt-5">
          <h3 className="text-gray-600 text-base mb-2">Service Duration</h3>
          <div className="flex items-center justify-between">
            <span>Standard</span>
            <input
              type="range"
              min="1"
              max="100"
              value="50"
              readOnly
              className="mx-2 w-full"
            />
            <span>Standard</span>
          </div>
          <div className="flex justify-between mt-2">
            <span>16 weeks</span>
            <span>150,000.5 THB</span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default SavingDetails;
