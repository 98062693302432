import React, { useEffect, useState } from "react";
import { Item, Section } from "..";
import { P } from "../../../components/Common/Typo/typo";
import { COLOR } from "../../../styles/constants";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { FaChevronDown, FaMinus, FaPlus } from "react-icons/fa";

interface TableBOQProps {
  data: Section[];
  setData: (data: Section[]) => void;
  editable?: boolean;
}

const TableBOQ2: React.FC<TableBOQProps> = ({
  data,
  setData,
  editable = true,
}) => {
  const [localData, setLocalData] = useState(data);

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  const handleInputUpdate = (
    sectionIndex: number,
    value: any,
    itemIndex?: number,
    field?: string
  ) => {
    if (itemIndex === undefined || field === undefined) {
      setData(
        data.map((section, index) =>
          sectionIndex === index
            ? {
                ...section,
                section_name: value,
              }
            : section
        )
      );
      return;
    }
    setData(
      data.map((section, index) =>
        sectionIndex === index
          ? {
              ...section,
              items: section.items.map((item, index) => {
                if (itemIndex !== index) return item;
                return {
                  ...item,
                  [field]: value,
                };
              }),
            }
          : section
      )
    );
  };

  const handleKeyPress = (
    event: React.KeyboardEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
    >,
    sectionIndex: number,
    value: any,
    itemIndex?: number,
    field?: string
  ) => {
    if (event.key === "Enter") {
      handleInputUpdate(sectionIndex, value, itemIndex, field);
      event.currentTarget.blur();
    }
  };

  const columns = [
    {
      key: "item_name",
      header: "Item Name",
      getValue: (row: Item) => row.item_name,
      span: 5,
      align: "left",
      editable: true,
      type: "text",
    },
    {
      key: "quantity",
      header: "Quantity",
      getValue: (row: Item) => row.quantity,
      span: 1,
      align: "center",
      editable: true,
      type: "number",
    },
    {
      key: "unit",
      header: "Unit",
      getValue: (row: Item) => row.unit,
      span: 1,
      align: "center",
      editable: true,
      type: "text",
    },
    {
      key: "unit_rate",
      header: "Unit Rate",
      getValue: (row: Item) => row.unit_rate,
      span: 2,
      align: "center",
      editable: true,
      type: "number",
    },
    {
      key: "total_price",
      header: "Amount",
      getValue: (row: Item) => row.quantity * row.unit_rate,
      span: 2,
      align: "center",
      editable: false,
    },
  ];

  return (
    <div className="flex flex-col gap-y-[8px] w-full h-full overflow-hidden">
      <div className="bg-white2 border-b-[2px] border-white3 px-[16px] py-[8px] grid grid-cols-11 w-full">
        {columns.map((column) => {
          return (
            <P
              color={COLOR.black2}
              className={`col-span-${column.span} w-full`}
              align={column.align ?? "center"}
            >
              {column.header}
            </P>
          );
        })}
      </div>
      <div className="flex flex-col gap-y-[8px] w-full overflow-y-scroll h-full">
        {data.map((section, sectionIndex) => (
          <div>
            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <DisclosureButton className="bg-[#C0E2FF] border-b-[2px] border-primary-light px-[16px] py-[8px] w-full flex justify-between items-center">
                    {editable ? (
                      <input
                        type="text"
                        id={`section_${sectionIndex}`} // Unique id attribute
                        name={`section_${sectionIndex}`} // Unique name attribute
                        className={`w-[50%] border border-gray-300 rounded px-[2px] py-[1px] text-primary-dark font-[600] text-[12px]`}
                        defaultValue={section.section_name.toString()}
                        value={localData?.[sectionIndex]?.section_name}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          setLocalData(
                            localData?.map((section, index) =>
                              sectionIndex === index
                                ? { ...section, section_name: e.target.value }
                                : section
                            )
                          );
                        }}
                        onBlur={(e) => {
                          e.stopPropagation();
                          handleInputUpdate(sectionIndex, e.target.value);
                        }}
                        onKeyDown={(e) => {
                          e.stopPropagation();
                          handleKeyPress(
                            e,
                            sectionIndex,
                            e.currentTarget.value
                          );
                        }}
                      />
                    ) : (
                      <P
                        color={COLOR.primary.dark}
                        fontWeight="600"
                        align="left"
                      >
                        {section.section_name}
                      </P>
                    )}
                    <div className="flex gap-[4px]">
                      {editable && (
                        <div
                          className={`flex justify-center items-center w-[16px] h-[16px] border rounded-[3px] shadow-cardborder bg-red-light border-status-offline`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setData(
                              data.filter((_, index) => index !== sectionIndex)
                            );
                          }}
                        >
                          <FaMinus
                            className={`w-[8px] h-[8px] text-status-offline`}
                          />
                        </div>
                      )}
                      <div
                        className={`flex justify-center items-center w-[16px] h-[16px] border rounded-[3px] shadow-cardborder ${
                          open
                            ? "bg-primary-medium border-primary"
                            : "bg-white border-white3"
                        }`}
                      >
                        <FaChevronDown
                          className={`w-[8px] h-[8px] ${
                            open ? "text-white" : "text-primary"
                          }`}
                        />
                      </div>
                    </div>
                  </DisclosureButton>
                  <DisclosurePanel>
                    {section.items.map((item, itemIndex) => (
                      <Disclosure defaultOpen>
                        <DisclosureButton
                          className={`relative ${
                            itemIndex % 2 ? "bg-white" : "bg-white4"
                          } border-b border-[#F2F3F5] px-[16px] py-[5px] grid grid-cols-11 gap-x-[2px] w-full items-center`}
                        >
                          {columns.map((column) => {
                            return editable && column.editable ? (
                              <input
                                type={column.type ?? "text"}
                                id={`${column.key}_${sectionIndex}_${itemIndex}`} // Unique id attribute
                                name={`${column.key}_${sectionIndex}_${itemIndex}`} // Unique name attribute
                                className={`col-span-${column.span} w-full border border-gray-300 rounded px-[2px] py-[1px] text-${column.align} text-[#272E3B] text-[12px]`}
                                defaultValue={column.getValue(item).toString()}
                                onClick={(e) => e.stopPropagation()}
                                value={
                                  localData?.[sectionIndex]?.items?.[
                                    itemIndex
                                  ]?.[column.key as keyof Item]
                                }
                                onChange={(e) => {
                                  setLocalData(
                                    localData?.map((section, index) =>
                                      sectionIndex === index
                                        ? {
                                            ...section,
                                            items: section?.items?.map(
                                              (item, index) =>
                                                itemIndex === index
                                                  ? {
                                                      ...item,
                                                      [column.key]:
                                                        e.target.value,
                                                    }
                                                  : item
                                            ),
                                          }
                                        : section
                                    )
                                  );
                                }}
                                onBlur={(e) => {
                                  e.stopPropagation();
                                  handleInputUpdate(
                                    sectionIndex,
                                    e.target.value,
                                    itemIndex,
                                    column.key
                                  );
                                }}
                                onKeyDown={(e) => {
                                  e.stopPropagation();
                                  handleKeyPress(
                                    e,
                                    sectionIndex,
                                    e.currentTarget.value,
                                    itemIndex,
                                    column.key
                                  );
                                }}
                              />
                            ) : (
                              <P
                                color="#272E3B"
                                className={`col-span-${column.span} w-full`}
                                align={column.align ?? "center"}
                              >
                                {column.getValue(item).toLocaleString()}
                              </P>
                            );
                          })}
                          {editable && (
                            <div
                              className={`absolute right-[16px] flex justify-center items-center w-[16px] h-[16px] border rounded-[3px] shadow-cardborder bg-red-light border-status-offline`}
                              onClick={(e) => {
                                e.stopPropagation();
                                setData(
                                  data.map((section, index) =>
                                    sectionIndex === index
                                      ? {
                                          ...section,
                                          items: section.items.filter(
                                            (_, index) => index !== itemIndex
                                          ),
                                        }
                                      : section
                                  )
                                );
                              }}
                            >
                              <FaMinus
                                className={`w-[8px] h-[8px] text-status-offline`}
                              />
                            </div>
                          )}
                        </DisclosureButton>
                        <DisclosurePanel>
                          <div
                            className={`w-full whitespace-pre-wrap bg-white3 text-[12px] px-[24px] py-[4px] text-black`}
                          >
                            <div
                              id={`description_${sectionIndex}_${itemIndex}`}
                              contentEditable={editable ? "true" : "false"}
                              className={`w-full ${
                                editable
                                  ? "bg-white border border-gray-300 rounded"
                                  : ""
                              } px-[2px] py-[1px] h-auto`}
                              role="textbox"
                              onBlur={(e) => {
                                handleInputUpdate(
                                  sectionIndex,
                                  document.getElementById(
                                    `description_${sectionIndex}_${itemIndex}`
                                  )?.innerText,
                                  itemIndex,
                                  "description"
                                );
                              }}
                            >
                              {item.description}
                            </div>
                          </div>
                        </DisclosurePanel>
                      </Disclosure>
                    ))}
                    {editable && (
                      <button
                        className="bg-black3 py-[3px] flex justify-center items-center w-full"
                        onClick={() => {
                          setData(
                            data.map((section, index) =>
                              sectionIndex === index
                                ? {
                                    ...section,
                                    items: [
                                      ...section.items,
                                      {
                                        item_name: "",
                                        quantity: 0,
                                        unit: "",
                                        unit_rate: 0,
                                        description: "",
                                      },
                                    ],
                                  }
                                : section
                            )
                          );
                        }}
                      >
                        <FaPlus className="w-[12px] h-[16px] text-primary" />
                      </button>
                    )}
                  </DisclosurePanel>
                </>
              )}
            </Disclosure>
            <div className="bg-white2 border-t-[2px] border-white3 px-[16px] py-[5px] grid grid-cols-11 w-full">
              <P
                color={COLOR.black2}
                className={`col-span-2 col-start-8 w-full`}
                align="center"
              >
                Subtotal
              </P>
              <P
                color={COLOR.black1}
                fontWeight="600"
                className="col-span-2 w-full"
                align="center"
              >
                {section.items
                  .reduce(
                    (acc, item) => acc + item.quantity * item.unit_rate,
                    0
                  )
                  .toLocaleString()}
              </P>
            </div>
          </div>
        ))}
        {editable && (
          <button
            className="bg-white3 py-[5px] flex justify-center items-center w-full"
            onClick={() => {
              setData([...data, { section_name: "", items: [] }] as Section[]);
            }}
          >
            <FaPlus className="w-[16px] h-[16px] text-primary" />
          </button>
        )}
      </div>
    </div>
  );
};

export default TableBOQ2;
